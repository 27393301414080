@import '~font-awesome/css/font-awesome.css';
@import "~bootstrap/dist/css/bootstrap.css";
@font-face {
    font-family: 'FuturaBT';
    src: url(./assets/components/fonts/futura/futura_light_bt.ttf) format("truetype");
    /* src: url(./assets/components/fonts/futura/Futura-CondensedLight.otf) format("otf"); */
}


/* You can add global styles to this file, and also import other style files */

body {
    margin: 0px;
    min-height: 100%;
    padding: 0;
    font-family: 'FuturaBT' !important;
    font-weight: normal;
    color: var(--secondary);
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    /* letter-spacing: 1px; */
    line-height: 1.5;
    background: var(--primary);
    background-image: url('./assets/components/images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    box-sizing: border-box;
}


/* @font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'),
    url('../fonts/fontawesome-webfont.woff2') format('woff2'),
    url('../fonts/fontawesome-webfont.woff') format('woff'),
    url('../fonts/fontawesome-webfont.ttf') format('truetype'),
    url('../fonts/fontawesome-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  } */

:root {
    --lightPrimary: #202427;
    --lightSecondary: #34393f;
    --opacitySecondary: #34393f1a;
    --custom-subheader-color: #a047c3;
    --lightAccent: #a047c3;
    --accentOutset: #701295 2px 2px 5px 0px, #70129510 -3px -3px 7px 0px;
    --lightInset: inset 2px 2px 11px 0 #000000, inset -4px -4px 6px 0 #40464E, inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    --lightOutset: 2.5px 2.5px 5px 0 #1a1b1f, -2.5px -2.5px 5px 0 #0e0d0f;
    --darkPrimary: #191d21;
    --white: #ffffff !important;
    --Black: #000000;
    --darkAccent: #701295;
    --rootcolor: #2f3338;
    --primary: var(--lightPrimary) !important;
    --secondary: #43454e !important;
    --ternary: rgb(255 255 255 / 64%);
    ;
    --accent: var(--lightAccent);
    --inset: var(--lightInset);
    --outset: var(--lightOutset);
    font-weight: 300;
    font-size: calc(6px + 1vw);
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--white);
}

::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #2f3338d1 inset !important;
    /* color: #fff!important; */
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    -webkit-text-fill-color: #aeaeab !important;
}

input:focus-visible {
    outline: unset !important;
}

.form-group {
    margin-bottom: 10px;
}

.text-danger {
    color: #818386 !important;
}

li {
    list-style: none;
}

.close_Filter {
    position: absolute;
    right: 3px;
    top: 7px;
    z-index: 999;
}

.to-message {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #fff !important;
}

.carousel-control-prev,
.carousel-control-next {
    width: unset !important;
}

textarea.form-control {
    height: auto !important;
}


/* @media screen and (-webkit-min-device-pixel-ratio:0)  { */

.outshadow {
    object-fit: contain;
    border-radius: 7px !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.inshadow {
    object-fit: contain;
    border-radius: 9px;
    -webkit-backdrop-filter: blur(21px);
    backdrop-filter: blur(21px);
    box-shadow: inset 2px 2px 11px 0 #000000, inset -4px -4px 6px 0 #40464E, inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border: solid 0.5px #3c3b3b;
    background-image: linear-gradient(281deg, #6a6b77 213%, #32343c 19%, #383a42 -48%), linear-gradient(281deg, #6a6b77 213%, #32343c 19%, #383a42 -48%);
}

.event-pricing-sec span.minus_wrap.inshadow {
    backdrop-filter: none;
}

.right_image {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1000;
}


/* .hide_div{
  display: none;
} */

.card .card-body {
    /* -webkit-backdrop-filter: blur(21px);
    backdrop-filter: blur(21px); */
    text-shadow: 1px 1px 2px #000000;
    /* background-image: linear-gradient(
110deg
, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 52%); */
    font-family: 'FuturaBT' !important;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 0.75; */
    letter-spacing: normal;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #d6d5d5cc;
}

.error {
    font-size: 80%;
    color: #dc3545;
    margin-top: 5px;
    -webkit-text-fill-color: #dc3545;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #4d2b6c !important;
    margin-right: 4px;
    background: #4d2b6c !important;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left;
    /* max-width: 100px; */
    max-width: 150px !important;
    font-size: 12px;
}

.multiselect-dropdown .dropdown-btn {
    padding: 5px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    border-width: 6px 6px 0 !important;
    /* right: -8px!important; */
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    line-height: 10px;
    padding: 4px 0;
}

.country-dropdown {
    background-color: transparent;
    min-width: 230px;
    color: #999;
    font-size: 14px;
}

.loginForm label {
    background-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #aaa9aa;
    font-family: 'FuturaBT';
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.6 !important;
}

.loginForm .form-control,
.intl-tel-input input,
.intl-tel-input input[type=text],
.intl-tel-input input[type=tel] {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    padding: 0.3rem 0.75rem;
    font-family: 'FuturaBT';
    color: #999 !important;
    border-radius: 0.25rem;
}

.loginForm .input-group-text {
    background-color: var(--primary);
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.loginForm .input-group-text .fa {
    padding: .375rem .75rem;
    cursor: pointer;
    z-index: 999;
}

.loginForm .input-group-prepend .input-group-text {
    padding: 14px !important;
    position: relative !important;
    color: #b5b7b8 !important;
}

.loginForm .input-group-prepend .input-group-text .flag_img {
    width: 22px !important;
}

.max-accordian .mat-expansion-panel-content {
    /* height: 70vh !important; */
    max-height: 100vh !important;
}

.mat-expansion-panel-content .mat-expansion-panel-body {
    /* max-height: 525px !important; */
    max-height: 55vh;
}

.card.credit_card .card-body {
    height: 60vh;
    overflow-y: auto;
}

.second-sec>.col-sm-8 {
    height: 62vh;
    overflow-y: auto;
}

.loginForm .form-control::-webkit-input-placeholder,
.loginForm select option {
    /* Chrome/Opera/Safari */
    /* color: #818386; */
    color: #fff;
    /* font-family: FontAwesome; */
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 12px;
    /* -webkit-backdrop-filter: blur(21px); */
    /* backdrop-filter: blur(21px); */
    text-shadow: 1px 1px 1px #9e9e9e;
    background-image: linear-gradient(129deg, rgba(255, 255, 255, 0) 33%, rgba(240, 240, 240, 0.19) 58%);
    font-family: 'FuturaBT';
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-control {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem;
    color: #999 !important;
    font-family: 'FuturaBT';
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #999;
    /* font-family: 'FontAwesome'; */
    font-family: 'FuturaBT';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 16px;
}

.StateWrap .mat-select-trigger,
.mat-filter {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem !important;
    color: #999 !important;
    font-family: 'FuturaBT';
    border-radius: 0.25rem;
}

.StateWrap .mat-select-placeholder {
    color: #999 !important;
    font-family: 'FuturaBT';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 14px;
    -webkit-text-fill-color: #999 !important;
}

.StateWrap .mat-form-field-label-wrapper {
    display: none !important;
}

.card {
    background-color: transparent !important;
    box-shadow: var(--inset);
}

.top-header {
    background-color: var(--primary);
    background: linear-gradient(-180deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    border-radius: 10px;
    /* min-width: 400px; */
    /* min-width: 730px; */
    /* width: 100%; */
    max-width: 100%;
    /* background-color: #2f3338;
    background-image: linear-gradient(
        270deg
         , rgba(255, 255, 255, 0) 84%, rgba(240, 240, 240, 0.19) 100%);
    background: linear-gradient(-180deg, rgba(52,55,61,1) 20%, rgba(27,31,36,1) 100%); */
}

.top-header .card {
    box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    padding: 10px;
    border-radius: 10px;
}

.top-header img {
    min-width: 80px;
    max-height: 80px;
    padding: 0 20px;
}

.top-header .title_img {
    align-self: center;
}


/* table tbody tr{
    object-fit: contain;
  border-radius: 7px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff;
  background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
  } */


/* .header-wrap{
    display: flex;
    align-content: center;
    justify-content: center;
}
.header-wrap .list-group-item{
    background-color: transparent;
    border: 0;
    padding: 10px;

}
.header-wrap a{
    width: 8%;
    height: 100%;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    color: var(--secondary);
} */

.dot_Icon {
    position: absolute;
    right: 0;
    font-size: 26px;
}

.dot_Icon .fa {
    color: var(--ternary) !important;
}

header {
    display: flex;
    width: 38%;
    /* max-width: 38%; */
    flex-direction: column;
    /* background-color: var(--primary); */
    /* box-shadow: 0 0 20px 0 var(--secondary); */
    padding: 10px 0 0 5px;
    /* position: fixed; */
    height: 100%;
}

.popover {
    box-shadow: var(--inset);
    background-color: var(--primary);
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    /* background-image: linear-gradient(112deg, rgba(255, 255, 255, 0) 32%, rgba(52,55,61,1) 90%) !important; */
    text-shadow: 1px 1px 2px #000000;
    -webkit-text-fill-color: #d6d5d5cc;
    box-shadow: 5px 5px 5px rgba(27, 31, 36, 1);
    border-radius: 7px;
    border: 1px solid rgba(27, 31, 36, 1);
}

.popover .popover-arrow {
    display: none;
}

.popover .btn-link {
    color: var(--accent);
}

.headerBtn {
    box-shadow: var(--outset);
    /* background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain; */
    /* height: 100px; */
    /* width: 100%; */
    min-width: 100px;
    padding: 20px;
}

.headerBtn:hover {
    box-shadow: var(--inset);
}

.table-bar {
    box-shadow: var(--inset);
    border-radius: 0.5rem;
}

.demo-button {
    box-shadow: var(--inset);
    flex: 1;
    height: 45px;
    width: 3.4rem;
    border-radius: 7px;
}


/* Toggle button */

.Employee_Toggle {
    color: rgb(255 255 255 / 64%);
    margin-top: 3px;
    margin-right: 5px;
}

.employee_togglebutton {
    display: flex;
    float: right;
}

.employee_onoff {
    height: 21px;
    width: 9%;
}


/* .employee_onoff.employeeToggle{
  height: 21px;
  width: 21%;
}
.employee_onoff.menuToggle{
  height: 21px;
  width: 6%;
}
.employee_onoff.itemToggel{
  height: 21px;
  width: 12%;
}

.employee_toggleswitch{
  margin-top: 5px;
}
.Table_Toggle .employee_toggleswitch{
  margin-top: 0px;
} */

.employee-table .form-control {
    margin-top: 10px !important;
    margin-left: 3px !important;
}

.employee-table .fa {
    color: var(--primary);
}

.switchToggle {
    position: relative;
    display: unset;
    z-index: 1;
}

.switchToggle input[type="checkbox"] {
    display: none;
}

.toggle-button-switch {
    position: absolute;
    /* top: -2px; */
    /* left: 15px; */
    width: 25px;
    height: 23px;
    background-color: rgb(60 63 66) !important;
    border-radius: 100%;
    cursor: pointer;
    z-index: 1000;
    transition: left 0.3s;
    object-fit: contain;
    -webkit-backdrop-filter: blur(21px);
    backdrop-filter: blur(21px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(176, 109, 255, 0), inset 1px 1px 2px 0 var(--white);
    background-image: linear-gradient(164deg, rgba(255, 255, 255, 0) 38%, rgba(240, 240, 240, 0.19) 89%);
}

.toggle-button-text {
    overflow: hidden;
    display: inline-flex;
    /* background-color: #fc3164; */
    background-color: #000000ba;
    border-radius: 25px !important;
    box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
    transition: 0.3s;
}

.toggle-button-text-on,
.toggle-button-text-off {
    float: left;
    width: 100%;
    height: 100%;
    padding: 1px 5px;
    /* line-height: 50px; */
    font-family: 'FuturaBT';
    font-weight: bold;
    color: #fff;
    text-align: center;
    font-size: 10px;
}

input[type="checkbox"]:checked~.toggle-button-switch {
    /* left: 45px; */
    right: 0;
}

input[type="checkbox"]:checked~.toggle-button-text {
    width: 90%;
    background-image: linear-gradient(303deg, rgb(78 52 120) 32%, rgb(21 21 31) 100%) !important;
}


/* input[type="checkbox"]:checked ~ .toggle-button-text.permissionButton {
  width: 9%;
  background-image: linear-gradient(303deg, rgb(78 52 120) 32%, rgb(21 21 31) 100%)!important;
}
input[type="checkbox"]:checked ~ .toggle-button-text.permissionButton.employeeToggle {
  width: 21%;
  background-image: linear-gradient(303deg, rgb(78 52 120) 32%, rgb(21 21 31) 100%)!important;
}
input[type="checkbox"]:checked ~ .toggle-button-text.permissionButton.menuToggle {
  width: 6%;
  background-image: linear-gradient(303deg, rgb(78 52 120) 32%, rgb(21 21 31) 100%)!important;
}
input[type="checkbox"]:checked ~ .toggle-button-text.itemToggel {
  width: 12%;
  background-image: linear-gradient(303deg, rgb(78 52 120) 32%, rgb(21 21 31) 100%)!important;
} */


/* .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
} */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
    top: 5px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 0px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round:before {
    border-radius: 50%;
    z-index: 100;
    position: absolute;
    top: 0px;
    /* left: 15px; */
    width: 23px;
    height: 22px;
    background-color: rgb(60 63 66) !important;
    border-radius: 100%;
    cursor: pointer;
    /* z-index: 1000; */
    transition: left 0.3s;
    object-fit: contain;
    -webkit-backdrop-filter: blur(21px);
    backdrop-filter: blur(21px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(176, 109, 255, 0), inset 1px 1px 2px 0 var(--white);
    background-image: linear-gradient(164deg, rgba(255, 255, 255, 0) 38%, rgba(240, 240, 240, 0.19) 89%);
}

.toggle-button-text-on,
.toggle-button-text-off {
    padding: 4px 4px;
    line-height: 15px;
}

.toggle-button-text {
    /* position: absolute; */
    height: 20px;
    width: 90%;
}

.pull-right-Permission .slider.round:before {
    left: 5px;
}

.logisticradio .slider.round:before {
    left: 3px;
}


/* .pull-right-Permission input:checked + .slider:before {
  transform: translateX(32px);
} */


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
    object-fit: contain;
    border-radius: 13px;
    -webkit-backdrop-filter: blur(21px);
    backdrop-filter: blur(21px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(176, 109, 255, 0), inset 1px 1px 2px 0 var(--white);
    background-image: linear-gradient(164deg, rgba(255, 255, 255, 0) 38%, rgba(240, 240, 240, 0.19) 89%);
}

.topHieImage {
    height: 25px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.slider {
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0px;
    bottom: 0;
    background-color: #646464a3;
    ;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: 0px;
    background-color: rgb(5 5 5);
    transition: .4s;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.mat-form-field-wrapper {
    padding: 0 !important;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.pull-right-active {
    /* position: absolute;
  right: 15px; */
    position: relative;
    float: right;
}

.pull-right-Permission {
    text-align: right;
    padding-top: 10px;
}


/* .checkbox{
    display: inline-flex;
    background: none;
    width: 1em;
    height: 1em;
    border-radius: .2em;
    box-shadow: var(--inset);
}
.selected{
    display: block;
    position: relative;
    margin: 0 auto;
} */

input .checkbox .selected {
    top: 2px;
    left: 1px;
    right: 0;
    bottom: 0;
    width: .3em;
    height: .5em;
    border: solid var(--secondary);
    border-width: 0 .1em .1em 0;
    transform: rotate(45deg);
}

.Search-bar {
    box-shadow: var(--inset);
    border: none;
    border-radius: 1rem;
    background: var(--primary);
    width: 12rem;
    height: 25%;
    margin-top: 18px;
}

.tabs {
    margin: 2px;
}

.radio_lost_wrap {
    position: relative;
}

.radio_lost_wrap .custom-control-label::before {
    top: 0;
    box-shadow: var(--inset);
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: none;
}

.radio_lost_wrap .custom-control-label::after {
    top: 0;
    width: 20px;
    height: 20px;
}

.radio_lost_wrap .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--primary);
    box-shadow: none;
}

.radio_lost_wrap a,
.reg_wrap p a {
    float: right;
    color: #818386;
    border-bottom: 1px solid #34393f;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
}

.checkbox-table {
    position: relative;
    /* text-align: center; */
}

.accordion-checkbox {
    text-align: left !important;
}

.accordion-checkbox .custom-control-label::before,
.accordion-checkbox .custom-control-label::after {
    margin: 0 5px !important;
}


/* .checkbox-table .custom-checkbox{
    float: right;
  } */

.checkbox-table .custom-control-label::before {
    top: 0;
    box-shadow: var(--inset);
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 5px;
    margin: 5px;
}

.checkbox-table .custom-control-label::after {
    top: 0;
    width: 20px;
    height: 20px;
    box-shadow: var(--inset);
    background-color: transparent;
    border-radius: 5px;
    margin: 5px;
}

.checkbox-table .custom-control {
    margin: 0 !important;
}

.checkbox-table .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--lightSecondary);
    box-shadow: none;
}

.checkbox-table a,
.reg_wrap p a {
    float: right;
    color: #818386;
    border-bottom: 1px solid #34393f;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
}

.text-box {
    font-size: 28px;
    text-shadow: var(--outset);
    color: rgb(255 255 255 / 64%);
}

.plus_btn {
    background: var(--primary);
    box-shadow: var(--outset);
    color: rgb(255 255 255 / 64%);
    padding: 2px 8px;
}

.linebar {
    width: 100%;
    height: 2px;
    background: var(--secondary);
}

.receipt_config .linebar {
    width: 100%;
    height: 2px;
    background: var(--secondary);
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-top: 0px !important;
}


/* mat table */

.table-container {
    /* max-height: 400px; */
    max-height: 55vh;
    overflow-y: auto;
    font-family: 'FuturaBT' !important;
}

.tableFilter {
    max-height: 380px !important;
}

.mat-header-row {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
}

.employee-table.dataTable thead tr {
    background-color: var(--darkPrimary) !important;
}

.employee-table.dataTable tr {
    /* box-shadow: var(--outset); */
    border-radius: 7px;
    margin: 2px 0;
    color: var(--ternary) !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.footer-table {
    position: relative;
}

.footer-table .mat-tab-label-container {
    object-fit: contain;
    border-radius: 7px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.footer-table .mat-tab-label .mat-tab-label-content {
    color: #d8d8d8;
    font-size: 18px;
}

.footer-table .mat-tab-label.mat-tab-label-active {
    opacity: 1;
}

.footer-table .employee-table.dataTable tr {
    box-shadow: unset;
}

.footer-table .employee-table.dataTable tr.highlight_row td {
    color: #fff !important;
    border-bottom: 1px solid var(--ternary);
}

.footer-table .employee-table.dataTable thead tr {
    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    box-shadow: 0 4px 8px 0 rgb(0 0 0), 0 6px 20px 0 rgb(0 0 0);
}

.footer-table .employee-table.dataTable tfoot tr {
    box-shadow: var(--inset);
}

.footer-table ul li {
    color: var(--ternary);
}

.footer-table .mat-sort-header-content,
.footer-table .footerData,
.footer-table span {
    color: #d8d8d8;
}

.footer-table .back-img {
    display: inline-flex;
}

.footer-table .back-img p {
    margin: 0 0 0 20px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: var(--rootcolor) !important;
    box-shadow: var(--inset);
    border: 1px solid #39393a !important;
}

.footer-table .back-img p span {
    font-size: 16px;
}

.footer-table .back-img p span.fa {
    margin: 0 10px;
}

.footer-table .back-img img {
    cursor: pointer;
}

.footer-table .back-img img:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    border: 1px solid #000000;
    border-radius: 7px;
}

.footer-table .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #d8d8d8;
    height: 1px;
}

.mat-table {
    background: transparent !important;
}

.table_center .mat-sort-header-container {
    justify-content: center;
}

table.dataTable.table_center .mat-cell {
    text-align: center;
    justify-content: center;
}

table.dataTable.table_center .mat-cell.highlight_td {
    text-align: left;
}

table.dataTable.table_center .mat-cell.highlight_td p {
    padding: 10px;
    background-color: #2f3338 !important;
    text-transform: uppercase;
    box-shadow: 0 4px 8px 0 rgb(0 0 0), 0 6px 20px 0 rgb(0 0 0);
    border-radius: 7px;
    min-width: 150px;
    display: inline-block;
    text-align: center;
    margin: 0;
}

table.dataTable.table_center tfoot td {
    text-align: center;
}

.mat-table thead,
.mat-table tbody,
.mat-table tfoot,
mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-table-sticky {
    background: transparent;
}

.mat-cell,
.mat-footer-cell,
.mat-header-cell {
    color: var(--ternary) !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    padding-left: 10px !important;
}


/* table.employee-table.dataTable td{
  padding: 8px 10px;
  background-color: #1c1c1c;
  border-top: none;
  vertical-align: middle;
  min-width: 100px;
  box-sizing: content-box;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
  margin: 2px 0;
  color: var(--ternary);
  border-collapse: separate;
    border-spacing: 0px 3px;
  background-color: transparent!important
} */


/* .employee.dataTable th{
  background-color: #552f77;
  background-color: #1c1c1c;
  font-weight: bold;
  padding: 8px 10px;
  border-bottom: 1px solid #111;
  color: #dbdbdb;
  font-size: 13px;
  opacity: 1;
  border-top: none;
  border-radius: 7px;
  border-collapse: separate;
    border-spacing: 0px 3px;
} */


/* mat accordion */

.mat-expansion-panel {
    background: transparent;
    color: rgb(255 255 255 / 64%);
}

.mat-expansion-panel-spacing {
    margin: 0 !important;
}

.mat-expansion-panel-header.mat-expanded {
    height: 48px;
}

.mat-expansion-panel-body {
    padding: 16px 24px !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
    color: rgb(255 255 255 / 64%);
}

.mat-expansion-panel-content {
    box-shadow: var(--inset);
    /* background-color: var(--secondary); */
    /* background-image: linear-gradient(
94deg
, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%); */
    max-height: 500px;
    overflow-y: auto;
}

.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
    background: transparent !important;
}

.tabhead_wrap {
    padding: 10px 20px;
    color: #d8d8d8;
    background-image: linear-gradient(-136deg, rgb(64 67 72) 20%, rgb(48 52 57) 100%);
    border-radius: 7px;
    box-shadow: var(--inset);
}

.card_wrap p {
    color: var(--ternary);
    margin: 0;
    font-size: 16px;
}

.card_wrap .row_border {
    border-bottom: 1px dashed #606060;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.table_screen td span {
    color: var(--ternary);
    font-size: 14px;
    font-weight: 300;
}

.table_screen td,
.table_screen p {
    color: var(--ternary);
    font-size: 16px;
    font-weight: 600;
}

.screenshot-table {
    max-height: 400px;
    overflow: auto;
}

.screenshot-table thead {
    display: none;
}

.screenshot-table tbody {
    max-height: 200px;
    overflow-y: auto;
}

.screenshot-table tfoot {
    border-top: 2px dashed var(--ternary);
}

.screenshot-table tr.mat-row,
tr.mat-footer-row {
    height: 30px;
}

.employeefilter {
    width: 100%;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a !important;
    color: #b0b0b0;
    border-radius: 5px;
}

.matemployeeinput {
    width: 100%;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a !important;
    color: #b0b0b0;
    border-radius: 5px;
    margin-left: 3px;
    position: relative;
}

.dropright .drop-item {
    /* display: block!important; */
    /* top: 0; */
    z-index: 999;
    top: 5px !important;
    width: 20%;
    position: relative;
    /* margin: 0 10px; */
    box-shadow: var(--inset);
    /* background-color: var(--secondary); */
    background-color: var(--primary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    border-radius: 7px;
    text-align: center !important;
    padding: 0px;
}

.dropright .drop-item.itemL3 {
    top: 35px !important;
}

.dropright .drop-item .dropdown-item {
    width: auto;
    height: auto;
    text-indent: unset;
    /* padding: 9px 10px; */
    background-clip: unset;
    border: none;
    display: flex;
    color: #999;
    font-family: 'FuturaBT' !important;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: inherit !important;
    font-size: 16px !important;
    text-align: left;
}

.dropdown-menu .dropdown-item:hover {
    object-fit: contain;
    border-radius: 7px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    cursor: pointer;
    background-color: var(--secondary);
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff; */
    /* background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%); */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 6px 6px 14px 0 #0d0e0f, -5px -5px 20px 0 rgba(136, 150, 167, 0.23);
    background-image: linear-gradient(167deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.mat-form-field {
    width: 100%;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    /* padding: 0!important; */
    font-size: 14px !important;
    font-family: 'FuturaBT' !important;
}

.mat-form-field-infix {
    border-top: none !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0px !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #999 !important;
    align-items: right !important;
    padding: 0px;
}

.mat-form-field .mat-select-arrow {
    color: #999 !important;
}

.mat-form-field-underline {
    display: none;
}

.mat-select,
.mat-select-value-text {
    font-family: 'FuturaBT';
}

.mat-select-trigger {
    line-height: 15px;
}

.timeEditBtn {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    color: #fff !important;
    font-weight: bold !important;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    width: auto;
    z-index: 2001;
    height: auto;
    text-indent: unset;
    /* padding: 9px 10px; */
    background-clip: unset;
    border: none;
    display: flex;
    color: var(--ternary);
    height: 1.5rem !important;
    /* color: #999;
  font-family: 'FontAwesome';
  font-style: normal !important;
  font-weight: normal !important;
  text-decoration: inherit !important;
  font-size: 16px !important; */
    background-color: var(--primary) !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option:hover {
    color: #999 !important;
}

.mat-select-value-text {
    text-indent: unset;
    /* padding: 5px !important; */
    background-clip: unset;
    border: none;
    /* display: flex; */
    color: #999 !important;
    font-family: 'FuturaBT';
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: inherit !important;
    font-size: 14px !important;
}

.mat-select-panel {
    background-color: transparent !important;
}

.mat-option-text {
    text-indent: unset;
    /* padding: 5px !important; */
    background-clip: unset;
    border: none;
    display: flex;
    line-height: 2rem;
    /* color: #999 !important; */
    font-family: 'FuturaBT';
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: inherit !important;
    font-size: 14px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--accent) !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
    color: var(--ternary);
}

.mat-select-placeholder {
    color: #999 !important;
}

.mat-form-field-infix {
    padding: 0px !important;
}

.mat-select-panel.mat-primary {
    position: absolute !important;
    /*dropup*/
    top: 30px;
    max-height: 150px;
    overflow-y: auto;
}

.mat-select-2-panel .mat-select-trigger .mat-select-panel-wrap {
    position: absolute !important;
    top: 26px;
}

.mat-box {
    z-index: 0 !important;
    top: 70px;
}


/* .mat-form-field{
  width: 90%;
} */

.mat-select-country .mat-autocomplete-panel-above .mat-autocomplete-panel {
    width: auto;
    z-index: 2001;
    height: auto;
    text-indent: unset;
    /* padding: 9px 10px; */
    background-clip: unset;
    border: none;
    display: flex;
    color: var(--ternary);
    height: 1.5rem !important;
    /* color: #999;
  font-family: 'FontAwesome';
  font-style: normal !important;
  font-weight: normal !important;
  text-decoration: inherit !important;
  font-size: 16px !important; */
    background-color: var(--primary) !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background-color: #1d2023 !important;
    /* color: #543371 !important; */
    color: var(--accent) !important;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
    background-color: var(--primary) !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    width: auto;
    z-index: 2001;
    height: auto;
    text-indent: unset;
    /* padding: 9px 10px; */
    background-clip: unset;
    border: none;
    /* display: flex; */
    color: var(--ternary);
    /* height:10px !important; */
}

.mat-input-element.mat-form-field-autofill-control.mat-autocomplete-trigger {
    padding: 0px;
    /* padding: 0 0.75rem; */
    height: 30px;
    /* box-shadow: var(--inset); */
    /* border-radius: 6px; */
    width: 100%;
}

.datalist {
    max-height: 200px;
    overflow-y: auto;
}

.builder_wrap {
    background-color: var(--primary);
    max-height: 150px;
    overflow-y: auto;
    padding: 10px;
    margin: 0;
}

.builder_wrap .row {
    box-shadow: var(--inset);
    border-radius: 6px;
    margin-bottom: 5px;
    padding: 3px 7px;
}

.ngx-timepicker-control__input {
    background-color: transparent;
}


/* .builder_wrap li:last-child{
  margin-bottom: 0;
} */

.builder_wrap li span {
    /* float: right; */
    cursor: pointer;
}

.country_wrap .mat-input-element.mat-form-field-autofill-control.mat-autocomplete-trigger {
    /* padding: 1px; */
    padding: 0 0 0 0.1rem;
    height: 30px;
    /* box-shadow: var(--inset); */
    /* border-radius: 6px; */
    width: 100%;
}

.country_wrap .mat-input-element.mat-form-field-autofill-control.mat-autocomplete-trigger::-webkit-input-placeholder {
    color: #999;
}

.mat-select-country .mat-form-field-flex {
    box-shadow: var(--inset);
    border-radius: 6px !important;
    background-color: var(--primary) !important;
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem;
    color: #999 !important;
    font-family: 'FuturaBT';
}

.mat-form-field-suffix {
    top: -10px;
}

.country_wrap .mat-form-field-suffix {
    top: -8px;
    right: 8px;
}

.mat-option {
    color: #aaa9aa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    /* height: 48px; */
    height: 1.5rem !important;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

.mat-cell.employeenodata {
    box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    color: #b0b0b0;
    text-align: center;
}

.matemployeelabel {
    color: #b0b0b0;
    display: flex;
    width: 100%;
}

.colorp .sketch-picker {
    margin: 0 auto;
}

.sketch-hue {
    height: 13px !important;
    width: 100% !important;
}

.color-hue-slider {
    margin-top: 0 !important;
    width: 13px !important;
    height: 13px !important;
    background-color: rgb(5 5 5) !important;
    border-radius: 50% !important;
    object-fit: contain;
    -webkit-backdrop-filter: blur(21px);
    backdrop-filter: blur(21px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(176, 109, 255, 0), inset 1px 1px 2px 0 var(--white) !important;
    background-image: linear-gradient(164deg, rgba(255, 255, 255, 0) 38%, rgba(240, 240, 240, 0.19) 89%);
}

#accordion .card-header {
    padding: 0 10px;
    border-radius: 5px;
}

#accordion .card-header a {
    padding: 10px;
    font-size: 16px;
    text-decoration: none;
}

#accordion .card-body {
    box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(-180deg, rgb(47 51 56) 20%, rgb(58 63 69) 100%);
    /* max-height: 500px; */
    overflow-y: auto;
    max-height: 50vh;
}

#accordion .collapse {
    /* background-color: #474952; */
    background-color: var(--primary);
    background: linear-gradient(-180deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
}

.mb-0>a {
    display: block;
    position: relative;
    text-shadow: 0 2px 1px #000000;
    border-style: solid;
    border-width: 0.3px;
    border-image-source: linear-gradient(to bottom, rgba(235, 232, 232, 0.01), #979797);
    /* border-image-slice: 1; */
    font-family: 'FuturaBT';
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgb(255 255 255 / 64%);
}

.mb-0>a:after {
    content: "\f078";
    /* fa-chevron-down */
    font-family: 'FontAwesome';
    position: absolute;
    right: 0;
}

.mb-0>a[aria-expanded="true"]:after {
    content: "\f077";
    /* fa-chevron-up */
}


/* .table-wrap{
    box-shadow:  var(--outset);
    border-radius: 7px;
    color: #b3b3b3;
    background-color: transparent !important;
    background-image: linear-gradient(
      362deg
      , rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
  } */

table.dataTable {
    border-collapse: separate;
    border-spacing: 0px 3px;
}

#checkbox-box {
    margin-top: -20px;
    margin-left: 40px
}

.currencyMask {
    text-align: left !important;
}

.country-dropdown {
    display: none !important;
}

.intl-tel-input .selected-flag .iti-arrow {
    display: none !important;
}

.dataTables_wrapper label {
    color: #b0b0b0;
    display: flex;
}

.dataTables_wrapper .dataTables_filter input {
    width: 100%;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a !important;
    color: #b0b0b0;
    border-radius: 5px;
}

.dataTables_wrapper .dataTables_filter {
    float: none !important;
    text-align: right;
}

.dataTables_wrapper .dataTables_empty {
    box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    color: #b0b0b0;
}

#time-table .dataTables_wrapper .dataTables_filter input {
    width: auto;
}

#time-table .dataTables_wrapper .dataTables_filter {
    float: right !important;
    text-align: right;
}

.dataTable thead {
    /* background-color: #552f77; */
    background-color: #1c1c1c;
    color: #dbdbdb;
    /* background-color: var(--Primary) !important;
  box-shadow: var(--inset);
  border: 1px solid #39393a; */
}

.dataTable tbody {
    background-color: var(--primary) !important;
}

.dataTable tbody tr,
tbody tr {
    /* box-shadow:  var(--outset); */
    border-radius: 7px;
    margin: 2px 0;
    color: var(--ternary) !important;
    background-color: transparent !important;
}

.flipAccordion table.dataTable tbody th,
.flipAccordion table.dataTable tbody td {
    padding: 0px 10px !important;
}

.flipAccordion table.dataTable tbody td .emp_name {
    margin: 10px !important;
}

.flipAccordion tr.mat-row,
tr.mat-footer-row {
    height: 0px !important;
}

.flipAccordion tr.mat-row.example-detail-row {
    box-shadow: none;
}

.time-hierachy-tab .modal-content {
    background-color: var(--rootcolor) !important;
    box-shadow: var(--inset);
    border: 1px solid #39393a !important;
    border-radius: 5px;
    /* color: var(--white); */
}

.time-hierachy-tab .modal-dialog {
    margin: 0 auto;
    max-width: 100% !important;
}

.time-hierachy-tab .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: grey;
    z-index: 90;
}

.timeDetails {
    margin: 10px;
}

.updateTime {
    /* margin: 10px; */
    margin: 0 auto 10px;
}

.updateTime img {
    width: 60px;
    height: 50px;
    cursor: pointer;
}

.timeEditModal .mat-elevation-z8 {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    color: var(--ternary) !important;
}

table.dataTable tbody th,
table.dataTable tbody td,
table.dataTable thead th,
table.dataTable tfoot td {
    padding: 8px 10px !important;
    border-top: none;
    vertical-align: middle;
    min-width: 120px;
    box-sizing: border-box;
    font-size: 14px;
}

tr.mat-footer-row {
    font-weight: bold;
}


/* timepicker timeedit */

.timeEditModal .timeeditpicker table {
    width: 100% ! important;
    border-radius: 7px;
    color: var(--ternary) !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.timeeditpicker .form-control {
    margin: 5px 2px !important;
    padding: 0 !important;
}

.flipAccordion table.dataTable .timeeditpicker tbody td,
.timeeditpicker tbody td {
    padding: 0px !important;
}


/* .timeEditModal .timeeditpicker table tbody tr td .bs-timepicker-field{
  width: 60px !important;
  margin: 3px 5px !important;
} */

.timeEditModal .timeeditpicker table tbody tr td .btn {
    padding: 0px 5px 0 0 !important;
    color: #aaa9aa;
}

.timeEditModal .timeeditpicker table tbody th,
.timeEditModal .timeeditpicker table tbody td,
.timeEditModal .timeeditpicker table thead th {
    min-width: 0px !important;
}


/* .timeEditModal .timeeditpicker table tbody th,
.timeEditModal .timeeditpicker table tbody td,
.timeEditModal .timeeditpicker table thead th {
  width: 250px !important;
} */


/* ///timepicker timeedit */

table.dataTable tbody td .emp_name {
    margin: 2px;
    padding: 5px 15px;
    object-fit: contain;
    border-radius: 7px;
    /* -webkit-backdrop-filter: blur(10px); */
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    cursor: pointer;
}

table.dataTable thead .sorting {
    background: url("/assets/components/images/sort_both.png") no-repeat center right;
    opacity: 0.5;
}

table.dataTable thead .sorting_asc {
    background: url("/assets/components/images/sort_asc.png") no-repeat center right !important;
    opacity: 1;
}

table.dataTable thead .sorting_desc {
    background: url("/assets/components/images/sort_desc.png") no-repeat center right !important;
    opacity: 1;
}


/* table.dataTable thead .sorting_asc_disabled {
  background: url("/assets/components/images/sort_asc_disabled.png") no-repeat center right;
  }
  table.dataTable thead .sorting_desc_disabled {
  background: url("/assets/components/images/sort_desc_disabled.png") no-repeat center right;
  } */

.example-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    /* min-height: 71px; */
    min-height: 5em;
}

.example-list .example-box {
    max-width: 16.66%;
}

.example-box {
    border-right: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: move;
    font-size: 14px;
    flex: 1 0 5rem;
    /* height: 71px; */
}

.eye_ball {
    border-radius: 14px;
    box-shadow: 0 2px 4px 0 rgba(54, 54, 54, 0.5), inset 1px 1px 2px 0 #ffffff !important;
}


/* .example-list .example-box{
  max-width: 16.66%;
} */


/* .cdk-drag-placeholder {
  opacity: 1;
} */

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


/* .cdk-drag-preview{
  visibility: hidden;
}  */

.cdk-drag-preview {
    box-sizing: border-box;
    height: 100%;
    box-shadow: var(--inset);
    border-radius: 8px !important;
    color: #aaa9aa;
    text-align: center;
    font-family: 'FuturaBT';
    background-image: linear-gradient(130deg, rgb(82 66 97) 32%, rgb(68 71 76) 100%);
    min-height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 24px !important;
}

.cdk-drag .mat-content {
    visibility: visible !important;
}


/* Menu Modifier drag and drop */

.menu_modifier_sec>.mat-expansion-panel-header.cdk-drag-placeholder+.mat-expansion-panel-header {
    display: unset !important;
}


/* .menu_modifier_sec .mat-expansion-panel-header{
  display: unset;
} */

.menu_modifier_sec .mat-expansion-panel {
    /* padding: 10px; */
    /* display: unset; */
}

.menu_modifier_sec .mat-expansion-panel-header:not([aria-disabled=true]) {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.menu_modifier_sec .mat-expansion-panel-header.cdk-drag-placeholder {
    /* display: unset; */
    padding: 15px 0px;
}

.menu_modifier_sec .mat-content {
    float: left;
    /* padding: 10px; */
}

.menu_modifier_sec .mat-expansion-indicator {
    float: right;
    /* padding: 10px; */
}


/* .menu_modifier_sec .mat-expansion-panel-header .mat-expansion-panel-header-title{
  padding: 10px 24px;
} */

.menu_modifier_sec .mat-expansion-panel-header.cdk-drag-placeholder .mat-content,
.menu_modifier_sec .mat-expansion-panel-header.cdk-drag-placeholder .mat-expansion-indicator {
    padding: 15px 24px;
}


/* .menu_modifier_sec .mat-expansion-panel{
  padding: 10px;
} */


/* item_assigned */

#accordion .annual_wrap {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    border: 1px solid #39393a;
    background: linear-gradient(194deg, rgba(52, 55, 61, 1) 70%, rgba(27, 31, 36, 1) 100%);
    padding: 5px;
}

#accordion .annual_wrap .form-group {
    margin: 0;
}

.job_wrap {
    max-height: 208px;
    overflow-y: auto;
    overflow-x: hidden;
}

.invalid-feedback {
    -webkit-text-fill-color: #dc3545 !important;
}

.job_wrap select.form-control {
    padding: 0;
}

.carouse_wrapper .carousel-indicators {
    display: block !important;
    /* top: 0; */
    position: relative;
    margin: 0 10px;
    box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    border-radius: 7px;
}

.carouse_wrapper .carousel-indicators li {
    width: auto;
    height: auto;
    text-indent: unset;
    padding: 9px 10px;
    background-clip: unset;
    border: none;
    /* background-color: transparent; */
    background-color: var(--primary);
    display: flex;
    /* box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(
94deg
 , rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%); */
}

.Permission_wrap .carouse_wrapper .carousel-indicators li {
    display: block;
}

.carouse_wrapper .carousel-indicators li.active {
    object-fit: contain;
    border-radius: 7px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff; */
    /* background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%); */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 6px 6px 14px 0 #0d0e0f, -5px -5px 20px 0 rgba(136, 150, 167, 0.23);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.carouse_wrapper .carousel-indicators li h6 {
    font-size: 16px;
}

.carouse_wrapper .carousel-item .card-body {
    /* min-height: 200px; */
    height: 100%;
    box-shadow: var(--inset);
    background-color: var(--secondary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    /* object-fit: contain;
    background-color: var(--primary)!important;
  border-radius: 7px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 6px 6px 14px 0 #0d0e0f, -5px -5px 20px 0 rgba(136, 150, 167, 0.23)!important;
  background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%)!important; */
}


/* .carouse_wrapper .carousel-indicators li h6{
  margin-left: 20px;
} */

.carousel-item .acclabel_wrap {
    display: flex;
    box-shadow: var(--inset);
    background-color: var(--primary);
    width: auto;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    vertical-align: middle;
    border-radius: 7px;
    padding-top: 5px;
    font-size: 16px;
}

.carousel-item .acclabel_wrap img {
    height: 70%;
}

.custom-control-input {
    z-index: 1 !important;
}


/* .modal.fade{
  display:flex;
  top:40%!important;
  justify-content: center;
  align-items: center;
} */

.modalComp .modal-content {
    background-color: #3e4246 !important;
    z-index: 2000 !important;
}

.modal-backdrop {
    opacity: 0 !important;
    display: none !important;
}

.modalComp .modal-header .close {
    margin: -1rem !important;
    padding: 0px !important;
}

.modalComp .remove_tab {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
}

.modalComp .modal-header {
    border-bottom: 0px !important;
    padding: 1rem 0 0 1rem !important;
}

.modalComp .modal-dialog.modal-dialog-centered {
    width: 35%;
    /* position: absolute; */
}

.modalComp .modal-footer {
    display: block !important;
    border-top: 0px !important;
}

.tablet_gen .tablet_save {
    bottom: 0px;
    position: absolute;
    right: 0;
}

.tablet_gen .empty_row {
    height: 30%;
}

.img_width {
    width: 70px;
}

.chat_label {
    color: rgb(255 255 255 / 64%);
}


/* .tablet_logistic */

.tablet_logistic .image_tab {
    width: 94%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    ;
}

.tablet_logistic .img-fluid.img_logistics {
    width: 94%;
    margin: 13px;
}

.tablet_logistic .table.dataTable {
    box-shadow: 0 2px 4px 0 rgb(13 12 12 / 50%), inset 1px 1px 8px 0 #202427;
}

.filterTableModal table.dataTable tbody td.mat-cell {
    border-bottom-color: rgb(216 216 216 / 20%) !important;
}

.screenshot {
    width: 30px;
}

.screenshot_div {
    border-radius: 3px;
    background-color: var(--primary) !important;
    color: #7c7e82;
    margin-right: 0px !important;
    margin-left: 0px !important;
    height: auto !important;
    text-align: center !important;
    border-bottom: 1px solid #5b5b58 !important;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.screenshot_div .time_screenshot {
    font-size: 10px;
    font-weight: bold;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 16px;
}

.screenshot_div .img_screenshot {
    padding-right: 0px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
}

.screenshot_div .screenshot_name {
    margin-top: 16px;
}


/* .tablet_logistic */


/* datepicker */

.bs-datepicker {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    /* background: #fff;
  box-shadow: 0 0 10px 0 #aaa; */
    box-shadow: var(--inset);
    background-color: var(--primary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    position: relative;
    z-index: 1;
}

.bs-datepicker:after {
    clear: both;
    content: "";
    display: block;
}

.bs-datepicker bs-day-picker {
    float: left;
}

.bs-datepicker button:active,
.bs-datepicker button:focus,
.bs-datepicker button:hover,
.bs-datepicker input:active,
.bs-datepicker input:focus,
.bs-datepicker input:hover,
.bs-datepicker-btns button:active,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:hover,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus {
    outline: 0;
}

.bs-datepicker-head {
    min-width: 270px;
    height: 50px;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    text-align: justify;
    object-fit: contain;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: var(--inset);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.bs-datepicker-head:after {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.bs-datepicker-head button {
    display: inline-block;
    vertical-align: top;
    padding: 0;
    height: 30px;
    line-height: 30px;
    border: 0;
    background: 0 0;
    text-align: center;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:active,
.bs-datepicker-head button[disabled]:hover {
    background: rgba(221, 221, 221, 0.3);
    color: #f5f5f5;
    cursor: not-allowed;
}

.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
    font-size: 28px;
    line-height: 1;
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.bs-datepicker-head button.current {
    border-radius: 15px;
    max-width: 155px;
    padding: 0 13px;
}

.bs-datepicker-head button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
    background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
    padding: 10px;
    border-radius: 0 0 3px 3px;
    min-height: 232px;
    min-width: 278px;
    /* border: 1px solid #e9edf0; */
}

.bs-datepicker-body .days.weeks {
    position: relative;
    z-index: 1;
}

.bs-datepicker-body table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.bs-datepicker-body table th {
    font-size: 13px;
    color: var(--lightAccent);
    font-weight: 400;
    text-align: center;
}

.bs-datepicker-body table td {
    color: #bcbcbc;
    text-align: center;
    position: relative;
    padding: 0;
}

.bs-datepicker-body table td span {
    display: block;
    margin: 0 auto;
    font-size: 13px;
    border-radius: 50%;
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
    cursor: pointer;
}

.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected),
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span {
    background-color: #e9edf0;
    transition: none;
}

.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected),
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span {
    background-color: #e9edf0;
    transition: none;
    cursor: pointer;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
    color: #9aaec1;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
    color: #fff;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
    color: rgb(170 170 170 / 25%);
}

.bs-datepicker-body table td.active {
    position: relative;
}

.bs-datepicker-body table td.active.select-start:before {
    left: 35%;
}

.bs-datepicker-body table td.active.select-end:before {
    left: -85%;
}

.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td.active.select-end span:after,
.bs-datepicker-body table td.active.select-start span:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    top: 0;
    border-radius: 50%;
}

.bs-datepicker-body table td span:before,
.bs-datepicker-body table td:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 6px;
    bottom: 6px;
    left: -2px;
    right: -2px;
    box-sizing: content-box;
    background: 0 0;
}

.bs-datepicker-body table td.active.select-start+td.active:before {
    left: -20%;
}

.bs-datepicker-body table td:last-child.active:before {
    border-radius: 0 3px 3px 0;
    width: 125%;
    left: -25%;
}

.bs-datepicker-body table td span[class*="select-"],
.bs-datepicker-body table td[class*="select-"] span {
    border-radius: 50%;
    color: #fff;
}

.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before,
.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before {
    background: #e9edf0;
}

.bs-datepicker-body table.days span {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.bs-datepicker-body table.days span.select-start {
    z-index: 2;
}

.bs-datepicker-body table.days span.in-range.select-end:before,
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
    background: 0 0;
    right: 0;
    left: 0;
}

.bs-datepicker-body table.days td.active+td.is-highlighted:before,
.bs-datepicker-body table.days td.active+td.select-end:before,
.bs-datepicker-body table.days td.in-range+td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range+td.select-end:before,
.bs-datepicker-body table.days td.select-start+td.is-highlighted:before,
.bs-datepicker-body table.days td.select-start+td.select-end:before {
    background: #e9edf0;
    width: 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
    border-radius: 3px 0 0 3px;
    left: 0;
    width: 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
    border-radius: 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
    width: 46px;
    height: 46px;
    line-height: 45px;
    margin: 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
    margin-bottom: 8px;
}

.bs-datepicker-body table.months td {
    height: 52px;
}

.bs-datepicker-body table.months td span {
    padding: 6px;
    border-radius: 15px;
}

.bs-datepicker .current-timedate {
    color: #54708b;
    font-size: 15px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    border: 1px solid #e9edf0;
    margin-bottom: 10px;
    cursor: pointer;
    text-transform: uppercase;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
    content: "";
    width: 15px;
    height: 16px;
    display: inline-block;
    margin-right: 4px;
    vertical-align: text-bottom;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
    border-radius: 4px 0 0 4px;
}

.bs-datepicker-multiple+.bs-datepicker-multiple {
    margin-left: 10px;
}

.bs-datepicker-multiple .bs-datepicker {
    box-shadow: none;
    position: relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
    padding-right: 10px;
}

.bs-datepicker-multiple .bs-datepicker+.bs-datepicker:after {
    content: "";
    display: block;
    width: 14px;
    height: 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
    position: absolute;
    top: 25px;
    left: -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
    float: left;
}

.bs-datepicker-multiple .bs-datepicker .right {
    float: right;
}


/* .bs-datepicker-container {
  padding: 15px;
} */

.bs-datepicker .bs-media-container {
    display: flex;
}

.footer_values h6,
.footerData h6 {
    font-size: 12px;
    text-transform: uppercase;
}


/* Filter table Modal */

.filterTableModal .modal-content {
    /* background-color: var(--darkPrimary)!important; */
    background-color: #2f3338 !important;
    object-fit: contain;
    border-radius: 7px !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.filterTableModal .modal-content .close {
    color: var(--white) !important;
    float: unset !important;
    font-size: 1rem;
}

.filterTableModal .modal-content .modal-body {
    padding: 15px !important;
}

.filterTableModal table thead tr {
    background-color: #2f3338;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    border-radius: 7px;
}

.filterTableModal .submit_button {
    font-size: 18px;
    padding: 0px 10px;
}

.filterTableModal select.form-control {
    margin-right: 5px;
}

.filterTableModal th.mat-header-cell:first-of-type,
.filterTableModal td.mat-cell:first-of-type,
.filterTableModal td.mat-footer-cell:first-of-type {
    /* padding-left: 10px!important; */
    text-align: center;
}


/* .filterTableModal .mat-form-field-label-wrapper{
  top: 0!important;
} */

.filterTableModal .mat-form-field-label-wrapper .mat-form-field-label {
    color: var(--ternary);
    padding-left: 5px;
}

.filterTableModal .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--lightAccent) !important;
}

.filterTableModal .mat-form-field-infix {
    width: 100% !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    /* background-color: var(--darkAccent)!important; */
    box-shadow: inset 2px 2px 11px 0 #000, inset -4px -3px 6px 0 #2e3339, inset 0 1px 3px 0 rgb(0 0 0 / 50%);
    background-color: #5d0780 !important;
}

.mat-checkbox-inner-container {
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 1px 0 #ffffff !important;
    border-radius: 3px;
}

.filterTableModal .mat-checkbox-layout {
    margin: 0;
}

.modal {
    /* z-index: 999!important; */
    z-index: 1000 !important;
}

.Sign_wrap .heading {
    color: var(--ternary);
    border-bottom: solid 2px var(--ternary);
    margin: 20px;
    background-color: var(--ternary);
    border-radius: 7px;
}


/* .filterTableModal .mat-select-panel.mat-primary{
  z-index: 1001!important;
} */


/* } */


/* ----------------------------------- schedule style ------------------------------  */


/*toaster  */

.popUpModal .modal-content {
    background-color: var(--darkPrimary) !important;
    object-fit: contain;
    border-radius: 0px !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: var(--outset);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.popUpViewModal .modal-content {
    background-color: var(--darkPrimary) !important;
    object-fit: contain;
    border-radius: 0px !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: var(--outset);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.popUpModal .modal-content .close {
    color: var(--white) !important;
    font-size: 1rem;
    margin-top: -14px;
}

.popUpModal .modal-content .modal-body {
    padding: 0px !important;
}

.popUpModal select.form-control {
    margin-right: 5px;
}

.popUpModal th.mat-header-cell:first-of-type,
.popUpModal td.mat-cell:first-of-type,
.popUpModal td.mat-footer-cell:first-of-type {
    /* padding-left: 10px!important; */
    text-align: center;
}


/* .popUpModal .mat-form-field-label-wrapper{
  top: 0!important;
} */

.popUpModal .mat-form-field-label-wrapper .mat-form-field-label {
    color: var(--ternary);
    padding-left: 5px;
}

.popUpModal .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--lightAccent) !important;
}

.popUpModal .mat-form-field-infix {
    width: 100% !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    /* background-color: var(--darkAccent)!important; */
    box-shadow: inset 2px 2px 11px 0 #000, inset -4px -3px 6px 0 #2e3339, inset 0 1px 3px 0 rgb(0 0 0 / 50%);
    background-color: #5d0780 !important;
}

.mat-checkbox-inner-container {
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 1px 0 #ffffff !important;
    border-radius: 3px;
}

.popUpModal .mat-checkbox-layout {
    margin: 0;
}

.popUpModal .modal-content .modal-body .remove_tab {
    text-align: center;
    border-radius: unset;
}

.popUpModal .remove_tab {
    background-image: linear-gradient(52deg, #341a46 32%, rgb(54 56 63) 100%) !important;
}

.popUpModal.success .remove_tab {
    background-image: linear-gradient(52deg, #224b17 32%, rgb(54 56 63) 100%) !important;
}

.popUpModal.errorpop .remove_tab {
    background-image: linear-gradient(52deg, #942626 32%, rgb(54 56 63) 100%) !important;
}


/* toaster */

.border_sec {
    border-bottom: solid 1px gray;
}

.selectWeekdays input,
.calederDates_wrap input {
    display: none !important;
}

.checkbox_wrap {
    display: flex;
}

.selectWeekdays input[type=checkbox]+label {
    display: inline-block;
    border-radius: 7px;
    background-color: #1d1d1d;
    /* height: 35px; */
    width: 50px;
    margin: 0px 2px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #aaa9aa !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #000 !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    /* border: none; */
}

.selectWeekdays input[type=checkbox]:checked+label {
    /* background: #007be4; */
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    background-color: rgb(52, 26, 70);
    color: #aaa9aa !important;
}

.scheduleForm.loginForm label {
    align-self: center;
}

.scheduleForm.loginForm .Week_wrap .custom-control-label {
    padding-top: 4px;
}

.calederDates_wrap {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    padding: 10px 10px 0;
}

.date_wrap {
    display: inline-block;
}

.calederDates_wrap input[type=checkbox]+label {
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    /* height: 35px; */
    width: 32px;
    margin: 0px 4px;
    padding: 5px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #aaa9aa !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #000!important;
  background-image: linear-gradient(
94deg
, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%)!important; */
}

.calederDates_wrap input[type=checkbox]:checked+label {
    /* background: #007be4; */
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    background-color: rgb(52, 26, 70);
    color: #aaa9aa !important;
}

.multiItem_wrap {
    border: 1px solid #aaa9aa;
    padding: 10px;
    border-radius: 7px;
}

.multiItem_wrap h6 {
    color: #aaa9aa;
    font-family: 'FuturaBT';
    font-size: 16px;
    font-weight: 900;
    text-align: left;
}

.multiItem_wrap .ngx-mat-timepicker .separator-colon {
    margin-left: 3px;
    margin-right: 3px;
}

.multiItem_wrap .ngxMatTime {
    display: inline-flex;
}

@media (max-width: 1024px) and (min-width: 768px) {
    header {
        min-width: 38%;
    }
    .breadcrumb-item .icons {
        margin: 0 0px;
    }
}

@media (max-width: 768px) {
    .bs-datepicker .bs-media-container {
        flex-direction: column;
    }
    header {
        /* max-width: 38%; */
        min-width: 40%;
    }
    .breadcrumb-item .icons {
        margin: 0 0px;
    }
    .modalComp .modal-dialog.modal-dialog-centered {
        width: 75%;
        margin: auto;
        /* position: absolute; */
    }
    .checkbox_wrap {
        display: block;
    }
}

.bs-datepicker-custom-range {
    padding: 15px;
    background: #eee;
}

.bs-datepicker-predefined-btns button {
    width: 100%;
    display: block;
    height: 30px;
    background-color: #9aaec1;
    border-radius: 4px;
    color: #fff;
    border: 0;
    margin-bottom: 10px;
    padding: 0 18px;
    text-align: left;
    transition: 0.3s;
}

.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:hover {
    background-color: #54708b;
}

.bs-datepicker-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #e9edf0;
}

.bs-datepicker-buttons .btn-default {
    margin-left: 10px;
}

.submit_button_error {
    border: 3px solid #a43541a3 !important;
}

.submit_button.btn {
    background-color: rgb(57 61 66);
}


/* .bs-timepicker-container {
  padding: 10px 0;
}
.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px;

}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
} */

bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 1000;
}

.intl-tel-input .country-list {
    background-color: #2b2d33 !important;
}

.country-dropdown {
    border: 1px solid #4b4f53 !important;
}

@media (max-width: 768px) {
    .bs-datepicker-multiple {
        display: flex;
    }
    .bs-datepicker-multiple+.bs-datepicker-multiple {
        margin-top: 10px;
        margin-left: 0;
    }
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td span[class*="select-"]:after,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td[class*="select-"] span:after,
.theme-default .bs-datepicker-head {
    background-color: #777;
}

.theme-default .bs-datepicker-body table td.week span {
    color: #777;
}

.theme-default .bs-datepicker-body table td.active-week span:hover {
    cursor: pointer;
    background-color: #777;
    color: #fff;
    opacity: 0.5;
    transition: none;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: var(--accent);
}

.theme-green .bs-datepicker-body table td.week span {
    /* color: #5cb85c; */
    color: var(--accent);
}

.theme-green .bs-datepicker-body table td.active-week span:hover {
    cursor: pointer;
    background-color: var(--accent);
    color: #fff;
    opacity: 0.5;
    transition: none;
}


/* hierachy */

.hierachy_wrap {
    display: flex;
    position: relative;
    border-radius: 5px;
    background-color: var(--rootcolor) !important;
    box-shadow: var(--inset);
    border: 1px solid #39393a !important;
    overflow-x: auto;
}

.hierachy_wrap .nav-tabs {
    border: none;
}

.hierachy_wrap ul .nav-link {
    padding: 0;
}

.hierachy_wrap .hierachy-tab {
    width: 100%;
    max-width: 300px;
    position: absolute;
    right: 0;
    top: -2px;
}

.mat-expansion-panel-body {
    max-height: 450px;
    overflow-y: auto;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    object-fit: contain;
    border-radius: 5px !important;
    -webkit-backdrop-filter: blur(10px);
    background-color: var(--secondary) !important;
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    border: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: none;
}

.breadcrumb-item {
    display: flex;
    margin: -4px !important;
}

.breadcrumb-item .icons {
    /* object-fit: contain;
  color: white;
  padding-top: 20px; */
    object-fit: contain;
    color: #9e9e9e;
    padding-top: 10px;
    margin: 0 5px;
    z-index: 1;
    /* vertical-align: middle*/
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0px !important;
}

.breadcrumb {
    background-color: transparent !important;
    margin-bottom: 0px !important;
    padding: 5px 3px !important;
}

.breadcrumb-item .nav-link {
    padding: 0 1px !important;
    margin: 0 !important;
    border: 0;
}


/* .breadcrumb-item .nav-link img{
  width: 70%;
} */

.hierachy-tab .modal-content {
    background-color: var(--rootcolor) !important;
    box-shadow: var(--inset);
    border: 1px solid #39393a !important;
    border-radius: 5px;
    color: var(--ternary) !important;
}

.hierachy-tab .modal-dialog {
    margin: 0 auto;
}

.hierachy-tab .modal-body {
    max-height: 550px;
    overflow-y: auto;
    padding: 0 0 10px;
}

.hierachy-tab .close {
    /* text-align: right; */
    position: absolute;
    right: 10px;
    z-index: 100;
}

.hierachy_wrapper {
    padding: 5px 10px;
}

.company_table_wrap thead {
    display: none;
}

.text_clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 1.7;
}

.submit_button {
    object-fit: contain;
    border-radius: 7px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: var(--outset);
    color: var(--ternary) !important;
    cursor: pointer;
    padding: 0;
    /* background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important; */
}

.submit_button img {
    height: 40px;
}

.box_button img {
    height: 100% !important;
}

.submit_button label {
    display: block;
}

.submit_button:hover {
    box-shadow: var(--outset) !important;
    color: var(--ternary);
}

.submit_button:focus {
    box-shadow: var(--inset) !important;
}

.submit_button:disabled {
    cursor: not-allowed;
}

.credit_card_button:disabled {
    cursor: not-allowed;
}

.submit_button.refresh {
    box-shadow: unset !important;
}

.submit_button.refresh:hover {
    box-shadow: var(--outset) !important;
    color: var(--ternary);
}

.submit_button.refresh:focus {
    box-shadow: var(--inset) !important;
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: #000;
    opacity: .4;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--darkAccent);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--darkAccent);
}

.custom-break-label {
    text-shadow: 1px 1px 2px #000000;
    /* background-image: linear-gradient(
110deg
, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 52%); */
    font-family: 'FuturaBT';
    font-stretch: normal;
    font-style: normal;
    /* line-height: 0.75; */
    letter-spacing: normal;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #d6d5d5cc;
}


/* .sketch-fields-container{
  display: none;
} */


/* start of menu item general */

.mat-button-toggle {
    padding: 0;
}

.mat-button-toggle-label-content {
    margin-top: -6px;
}

.mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    height: 50%;
    padding: 0px;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-left: solid 1px rgba(0, 0, 0, .12);
    height: 50% !important;
    padding: 0px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    margin: -10px !important
}


/* Start of Menu item Logistic */

.sketch-fields {
    display: none !important;
    padding-top: 4px;
}

.sketch-saturation {
    /* display: none !important; */
}

.sketch-swatches {
    display: none !important;
}

.sketch-color {
    display: none !important;
}

.sketch-alpha {
    display: none !important;
}

.sketch-picker {
    background: transparent !important;
    padding: 28PX 0 0 0!important;
    box-sizing: initial;
    background: #fff;
    border-radius: 4px;
    box-shadow: none !important;
    width: 100% !important;
    position: relative;
}

.color-saturation {
    position: relative;
    height: 91px;
    width: 121px;
}

.kds .color-saturation {
    position: relative;
    height: 91px;
    width: 140px;
}

.sketch-controls {
    position: absolute;
    top: 0;
    width: 100%;
}


/* Timepicker */


/* .ngx-mat-timepicker .mat-icon{
  display: none !important;
  width: 0px !important;
  height: 0px !important;
} */

.mat-mini-fab {
    width: 0px !important;
    min-width: 0px !important;
}

.ngxMatTime .ngx-mat-timepicker {
    display: table-cell;
    border-radius: 7px;
    padding: 2px;
    max-height: 40px;
    height: 100%;
    color: var(--ternary) !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.ngxMatTime .mat-form-field .ngx-mat-timepicker-control .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.ngxMatTime .mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.ngxMatTime .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px !important;
}

.ngxMatTime .mat-form-field-infix {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    text-align: center;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem;
    color: #999 !important;
    font-family: 'FuturaBT';
    border-radius: 7px;
}

.ngxMatTime .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #aaa9aa !important;
}

.ngx-mat-timepicker .period-select {
    /* width: 60px; */
    min-width: 40px !important;
    margin-left: 6px !important;
}

.ngx-mat-timepicker-control {
    width: 45px !important;
    min-width: 40px !important;
}

.ngx-mat-timepicker-control input {
    text-align: center;
    vertical-align: -webkit-baseline-middle;
}

.ngx-mat-timepicker-control .arrows-wrap {
    bottom: -25px !important;
}

.ngxMatTime .mat-select {
    top: 6px;
    position: relative;
}


/* salesAreaLayout */

.menu_wrapper .carousel-control-prev,
.carousel-control-next {
    height: 70px;
    /* margin-top: 65px; */
    box-shadow: var(--inset);
    margin-left: -3px;
    margin-right: -4px;
}

.menu_wrapper .drag-scroll-content {
    overflow: hidden !important;
}

.menu_wrapper .drag-scroll {
    min-height: 70px;
    width: 100%;
}

.salesAreaLayout {
    width: 100%;
    border: 1px solid #ccc;
}

.salesAreaLayout .tablet .tabletRow {
    margin: 0px !important;
    min-height: 70px !important;
    width: auto;
    /* display: flex; */
}

.salesAreaLayout .tab-data {
    padding: 0px !important;
    border-right: 1px solid;
}

.salesAreaLayout .tablet {
    background-image: linear-gradient(152deg, rgb(25 25 25 / 0%) 32%, rgb(210 209 209 / 19%) 100%);
    box-shadow: var(--outset);
    width: 100%;
    height: 67px;
    /* overflow: hidden; - if i put this left or right click funcn wont work */
}

.menu_wrapper .dropzone-placeholder {
    /* margin: 5px; */
    /* height: 100%; */
    /* padding-top: 20px;
  padding-bottom: 20px; */
    box-shadow: var(--inset);
    /* border-radius: 8px; */
    color: #aaa9aa;
    text-align: center;
    cursor: move;
    font-family: 'FuturaBT';
    background-color: #341a46;
    /* background-image:linear-gradient(130deg, rgb(82 66 97) 32%, rgb(68 71 76) 100%); */
    min-height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout_wrapper .dropzone-placeholder {
    min-height: 5em;
}

.drop_wrapper .dropzone-placeholder,
.drop_wrapper .emp_name {
    /* margin: 5px; */
    /* height: 100%; */
    /* padding-top: 15px;
padding-bottom: 15px; */
    box-shadow: var(--inset);
    /* border-radius: 8px; */
    color: #aaa9aa;
    text-align: center;
    cursor: move;
    font-family: 'FuturaBT';
    background-image: linear-gradient(130deg, rgb(82 66 97) 32%, rgb(68 71 76) 100%);
    min-height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
}

#toDoMulti .dropzone-placeholder {
    margin: 0;
    cursor: move;
}


/*  salesAreaLayout */


/* itemsLayout */

.items_layout_plus_btn {
    backdrop-filter: none !important;
}

.new_divs {
    height: 70px;
    display: block;
    margin: 0px 10px !important;
    overflow: hidden;
}

.new_multiDivs {
    height: 70px;
    width: 96%;
    float: left;
    background-image: linear-gradient(152deg, rgb(25 25 25 / 0%) 32%, rgb(210 209 209 / 19%) 100%);
}

.items_layout_tablet {
    /* background-image:linear-gradient(
        152deg
         , rgb(25 25 25 / 0%) 32%, rgb(210 209 209 / 19%) 100%); */
    /* width: 96%; */
    width: 100%;
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    /* border: 2px dotted gray; */
    padding-top: 1em;
}

.items_layout_tab-data {
    width: 20%;
    height: 70px;
    border: 1px solid gray;
}

.item_tablet {
    margin: 10px 10px 0px 10px !important;
}

.drop-container.drop_wrapper {
    /* min-height:4em; */
    min-height: 4.5em;
    border: 1px solid gray;
    padding: 2px;
    /* margin-bottom: 2%; */
}

.deleteicon {
    padding: 5px;
    text-align: center;
}

.deleteItem {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #878489;
    font-size: 10px;
    cursor: pointer;
}

.div_labelName {
    font-size: 14px;
    /* padding: 8px;
  object-fit: contain;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff;
  background-image: linear-gradient(
    130deg, rgb(82 66 97) 32%, rgb(68 71 76) 100%); */
}

.modifiername {
    padding: 0 !important;
}

.modifiername .modify_gen {
    width: 40%;
}


/* dynamic edit table */

.dynamic_table {
    width: 100%;
}

.dynamic_table .mat-form-field {
    width: 100px !important;
    text-align: center;
}

.dynamic_table .mat-form-field.mat-focused {
    box-shadow: var(--outset);
    height: 40px;
    max-width: 30%;
    /* display: flex;
  align-items: center;
  justify-content: center; */
    display: inline-grid;
    place-items: center;
    justify-content: center;
}


/* .dynamic_table .mat-form-field.mat-focused .mat-form-field-infix{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.dynamic_table td.mat-cell {
    border-right-style: inset;
    border-right-color: rgba(0, 0, 0, .12);
}

.dynamic_table th.mat-header-cell {
    border-right-style: inset;
    border-right-color: rgba(0, 0, 0, .12);
    text-align: center;
    background-color: #3a3e41;
}

.dynamic_table .dynamic_head {
    border-radius: 3px;
    margin: 2px 0;
    color: var(--ternary) !important;
    background-color: rgb(74 56 92) !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    /* text-align: left !important; */
    font-size: 14px !important;
}


/* .dynamic_table .mat-form-field-infix{
  display: inline-flex;
} */

.dynamic_table .dynamic_Item:nth-child(2) {
    border-radius: 3px;
    margin: 2px 0;
    color: var(--ternary) !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgb(13 12 12 / 50%), inset 1px 1px 8px 0 #202427;
    background-image: linear-gradient(49deg, rgb(13 11 11 / 0%) 32%, rgb(69 72 74) 100%);
    text-align: center !important;
}

.dynamic_table .dynamic_Item:nth-child(2) .mat-form-field .priceLabel {
    display: none;
}

.dynamic_table .dynamic_Item {
    text-align: center !important;
    background-color: #3a3e41;
    /* z-index: 999; */
    /* z-index: 2000;
  box-shadow:0 2px 4px 0 rgb(13 12 12 / 50%), inset 1px 1px 8px 0 #202427;
  background-image:  linear-gradient(
    49deg , rgb(13 11 11 / 0%) 32%, rgb(69 72 74) 100%) */
}

.dynamic_table .dynamic_Item:first-child {
    display: none;
}

.dynamic_table .dynamic_head:first-child {
    display: none;
}

.dynamic_body {
    max-height: 400px;
    overflow-y: auto;
}


/* .dynamic_table .mat-form-field.mat-focused .mat-input-element{
  max-width: 100%;
} */

.dynamic_table .dynamic_Item:nth-child(2) .mat-input-element {
    max-width: 100%;
}

.dynamic_table .mat-input-element {
    max-width: 45%;
}


/* dynamic edit table */

@media (min-width: 360px) and (max-width: 640px) {
    .items_layout_tablet {
        width: 88%;
    }
    .new_multiDivs {
        width: 88%;
    }
}

.cdk-drag.cdk-drop-list.row {
    margin: 0px !important;
}


/* itemsLayout */


/* AreaBuilder ItemLAyout */

.itemsLayout .plus_btn {
    padding: 0px 4px !important;
}


/* AreaBuilder ItemLAyout */


/* menu-size-general */

.menuSizeFormField {
    width: 130px !important;
}

.menuSizeGeneral .padR5 {
    padding-right: 5px;
}

.marR5 {
    margin-right: 5px;
    margin-top: 3px
}

.multi_Menu {
    overflow-y: auto;
}


/* menu-size-general */


/*  salesAreaLogistic */

.SaleareaLogistic .custom-radio .custom-control-label::before {
    background-color: transparent !important;
    box-shadow: var(--inset) !important;
}

.SaleareaLogistic .custom-control-input:checked~.Suggesttip.custom-control-label::before {
    color: #fff !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    background-color: rgb(52, 26, 70) !important;
}

.SaleareaLogistic .Suggesttip.custom-control-label::before {
    width: 18px !important;
    height: 18px !important;
    border: none !important;
    /* margin: auto !important; */
    padding: 0;
    margin-left: 9px !important;
}

.logisticradio {
    text-align: center !important;
}

.SaleareaLogistic .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    margin: 0 !important;
    line-height: 28px !important;
}

.SaleareaLogistic .accordion-checkbox {
    text-align: left !important;
    margin-left: 13px;
    text-align: center !important;
}

.SaleareaLogistic .custom-control.custom-checkbox.custom-control.item {
    padding-left: 40px;
}

.SaleareaLogistic .customlable {
    color: #999;
    padding-bottom: 4px;
    margin-left: 5px;
}

.SaleareaLogistic .main-item.row [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
}

.SaleareaLogistic .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none
}

;
.SaleareaLogistic .shadowtoggle {
    margin-top: 10px;
}

.SaleareaLogistic .card-header {
    padding: 0;
    text-align: center;
    height: 100% !important;
}

.SaleareaLogistic .card-header ul {
    border: none;
}

.SaleareaLogistic .card-header ul li {
    box-shadow: var(--inset);
    height: 35px;
}

.SaleareaLogistic .card-header ul li a {
    color: #999;
    text-decoration: none;
}

.SaleareaLogistic .card-header ul li a.active {
    color: #999;
    font-weight: bold;
    font-size: 16px;
    border: none;
    height: 35px;
    filter: grayscale(0%);
}

.SaleareaLogistic .card-header ul li a:hover {
    height: 35px;
}

.SaleareaLogistic .bold {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.logisticToggle {
    width: 100%;
    padding: 0;
}


/* fliptable-dragdrop */

.drop-container {
    /* position: relative; */
    height: auto;
    overflow: auto;
}

table.dropping {
    box-shadow: var(--outset);
}

.plus_accordian {
    margin-right: 15px
}

.accordion-tab {
    padding-left: 5px !important;
}

.modifierClicked {
    height: 400px;
    overflow-x: auto;
}


/* fliptable-dragdrop */


/*  salesAreaLogistic */


/* Menu Item Logistic */

.logisticsKDStime {
    margin-left: 40px;
}

.logistics {
    font-family: 'FuturaBT' !important;
    /* height: 300px; */
    overflow-x: hidden !important;
}

.col-6.form-control.input-lg.inputbox_login.logisticsmins {
    text-align: center;
    height: 50%;
    /* padding-top: 12px; */
    width: 50%;
}

.logisticcol {
    position: relative;
    width: 100%;
}

.mat-elevation-z8.outshadow {
    width: 100%;
    /* height: 150px !important; */
    text-align: center !important;
    max-height: 173px;
    overflow: auto;
}

.logisticitemname {
    text-align: center;
}

.logisticcanvas {
    padding: 5px;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    font-size: 14px;
    border: 1px solid #39393a;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem;
    border-radius: 7px;
    box-shadow: 8px -6px 26px 17px var(--PrimaryBgColor);
    margin: 8px 0;
}

.store-logisticcanvas {
    padding: 5px;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    font-size: 14px;
    border: 1px solid #39393a;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem;
    border-radius: 7px;
    box-shadow: none !important;
    margin: 8px 0;
}

.logisticcanvascolor {
    width: 38px !important;
    /* margin-left: 15px; */
    height: 34px !important;
    margin: 0 auto;
}

.custom-button-text.logisticcanvascolor {
    margin: 0;
}

.logisticchange {
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 14px 6px 6px 41px;
    width: 100%;
}

.logistic-colorpick {
    background-color: transparent;
    border: transparent;
    width: 70%;
}

.logisticfirstrow {
    margin-right: 0;
    margin-bottom: 50px;
    margin-left: 0;
    margin-top: 0;
}

.logistics .mat-slider-horizontal {
    height: 42px;
    min-width: 82%;
    margin: 0;
    padding: 0;
}

.menuitemlogisticrow {
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 7px 6px 6px 10px;
    width: 100%;
    border-radius: 5px;
}


/* Menu Item Logistic */


/* sale area general */

.SaleArea_general .form-check-input.deposite {
    margin-left: 44px;
    margin-top: 10px;
}

.SaleArea_general .invalid-feedback {
    display: flex !important;
}

.SaleArea_general .custom-radio .custom-control-label::before {
    background-color: transparent;
    box-shadow: var(--inset);
}

.SaleArea_general .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    background-color: rgb(52, 26, 70);
}

.SaleArea_general .custom-control-input {
    left: 5px;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.SaleArea_general .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none
}

;
.SaleArea_general .form-check-inline {
    display: grid !important;
}

.radiolabel {
    text-align: center;
    margin-bottom: 0 !important;
}

.SaleArea_general .radiorow {
    text-align: center !important;
}

.SaleArea_general .custom-control.custom-radio.custom-control.item {
    padding-left: 40px;
}

.bold {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.shadowtoggle {
    margin-top: 10px;
}

.SaleArea_general .custom-control-label::before {
    /* width: 18px !important; */
    /* height: 18px !important; */
    border: none !important;
    /* margin: auto !important; */
    padding: 0;
}


/* sale area general */


/* sale area logistic */

.tipToggle .form-control {
    padding: 0 !important;
    text-align: center;
}


/* sale area logistic */


/* Item Assigned */

.item_assigned .layout_container {
    height: 400px !important;
    /* overflow: auto !important; */
    overflow-y: auto !important;
    overflow-x: hidden;
}

.itemAssignedmodify .example-list {
    display: flex;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    min-height: 100%;
    flex-direction: column
}

.itemAssignedmodify .item_assigned_box {
    /* border-right: solid 1px #ccc; */
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: move;
    font-size: 14px
}

.item_assigned_label {
    padding: 28px 10px;
    border-top: none;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 7px;
    margin: 2px 0;
    color: var(--ternary) !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgb(39 42 46) 32%, rgb(83 87 88) 100%);
    background-color: var(--darkPrimary) !important;
    width: 100%;
    height: 14%;
    font-family: 'FuturaBT';
    line-height: 0;
    scrollbar-track-color: #999
}

.itemAssignedmodify {
    width: 100%;
    border: 1px solid #ccc;
    height: 340px !important;
    overflow-y: auto;
}

.ItemModifierDD .example-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 71px;
    flex-direction: unset
}

.modifierDD {
    padding: 5px 10px;
}

.ItemModifierDD {
    border: 1px solid #ccc;
}

.item_main {
    border-radius: 7px;
    /* margin: 2px 0; */
    color: var(--ternary) !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgb(43, 47, 50) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.item_main .emp_name {
    /* margin: 2px 9px; */
    padding: 10px;
    object-fit: contain;
    border-radius: 7px;
    /* -webkit-backdrop-filter: blur(10px); */
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    color: #dfdbdb;
    font-size: 14px;
}

.item_main .outshadow {
    padding: 5px 15px;
    /* background-color: #341a46; */
}


/* Item Assigned End */


/* Modifier Layout */

.menuModifier_layout .mat-sort-header-content {
    margin: auto !important;
}

.menuModifier_layout .dropzone-placeholder {
    /* margin: 5px; */
    height: 100%;
    padding-top: 25px;
    /*padding-bottom: 20px; */
    box-shadow: var(--inset);
    /* border-radius: 8px; */
    color: #aaa9aa;
    text-align: center;
    font-family: 'FuturaBT';
    background-image: linear-gradient(130deg, rgb(82 66 97) 32%, rgb(68 71 76) 100%)
}


/* modifier layout */


/* .layout .dropzone-placeholder{
  background-color: transparent !important;
}  */


/* salesArea_layout */

.salesArea_layout .mat-sort-header-content {
    margin: auto !important;
}


/* salesArea_layout */


/* menuGroup_layout */

.menuGroup_layout .mat-sort-header-content {
    margin: auto !important;
}


/* menuGroup_layout */


/* menuPage_layoyt */

.menuPage_layoyt .mat-sort-header-content {
    margin: auto !important;
}


/* menuPage_layoyt */


/* item_layout */

.item_layout .mat-sort-header-content {
    margin: auto !important;
}


/* item_layout */


/* Menu Item General */

.MenuItemGeneral .customlable {
    color: #999;
    padding-bottom: 4px;
    margin-left: 5px;
}


/* .MenuItemGeneral .accordion-checkbox {
  text-align: center !important;
} */

.MenuItemGeneral .custom-title {
    padding-left: 5px;
    font-size: 16px;
}

.menuitemgeneralmulti {
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 7px 6px 6px 10px;
    width: 100%;
    border-radius: 5px;
}

.MenuItemGeneral .border-bottom {
    border-color: #676363 !important;
}

.MenuItemGeneral {
    overflow-x: hidden !important;
    overflow-y: auto;
}

.MenuItemGeneral .card-header {
    padding: 0;
    text-align: center;
    height: 100% !important;
}

.MenuItemGeneral .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border-radius: 4px;
    height: 100% !important;
    width: 100% !important;
}


/* Menu Item General */


/* menu Group general */

.menu_wrapper .layout_minus {
    float: right;
    margin-top: 30px;
}


/* menu Group general */

@media (min-width: 768px) {
    .media {
        width: 100% !important;
    }
}


/* End of Menu item Logistic */


/* payment uber eats */

.custom_Uber_eats .form-check-input.deposite {
    margin-left: 44px;
    margin-top: 10px;
}

.custom_Uber_eats .invalid-feedback {
    display: flex !important;
}

.custom_Uber_eats .custom-radio .custom-control-label::before {
    background-color: transparent;
    box-shadow: var(--inset);
}

.custom_Uber_eats .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    background-color: rgb(52, 26, 70);
}

.custom_Uber_eats .custom-control-input {
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom_Uber_eats .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none
}

;
.custom_Uber_eats .form-check-inline {
    display: grid !important;
}

.radiolabel {
    text-align: center;
    margin-bottom: 0 !important;
}

.custom_Uber_eats .radiorow {
    text-align: center !important;
}

.custom_Uber_eats .custom-control.custom-radio.custom-control.item {
    padding-left: 40px;
}

.bold {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.shadowtoggle {
    margin-top: 10px;
}

.custom_Uber_eats .custom-control-label::before {
    /* width: 18px !important; */
    /* height: 18px !important; */
    border: none !important;
    /* margin: auto !important; */
    padding: 0;
}

.custom_Uber_eats .uber_label {
    font-size: 14px;
    font-weight: bold;
    color: #999;
}

.custom_Uber_eats .uber_para {
    color: #787878;
}

.receipt_config .uber_para {
    color: #787878 !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: var(--darkAccent) !important
}

.mat-option.mat-focus-indicator.mat-option-multiple.mat-active.mat-selected {
    color: var(--darkAccent) !important;
}


/* payment uber eats */


/* house Accounts */

.Communication_tab {
    background-image: linear-gradient(-136deg, rgb(64 67 72) 20%, rgb(48 52 57) 100%);
    border-radius: 3px;
    box-shadow: var(--inset);
    padding: 5px 10px;
    font-size: 20px;
}

.tablet_logistic .Communication_tab {
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tablet_logistic .dataTable tr {
    /* background-image: linear-gradient(
    -136deg, rgb(69 71 73) 20%, rgb(48 52 57) 100%); */
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0px -2px 1px 0 #999 !important;
    padding: 2px;
}

.Communication_Form {
    display: flex;
}

.com_label {
    padding: 14px;
}

.req_label {
    margin-bottom: 0rem !important;
}


/* house Accounts */


/* creditCard */

.card.credit_card {
    background-color: #2b2f34 !important;
}

.mat-stepper-horizontal {
    background-color: transparent !important;
    font-family: 'FuturaBT' !important;
}

.mat-horizontal-stepper-header-container {
    display: none !important;
}

.mat-step-header {
    display: none !important;
}

.config {
    display: flex !important;
}

.customlable_credit {
    width: 46%;
}

.credit_toggle {
    border-radius: 3px;
    box-shadow: var(--outset);
}


/* .step_button {
  margin-top: 5%;
} */

.tsys_button {
    margin-top: 2%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.credit_firstData .form-check-input.deposite {
    margin-left: 44px;
    margin-top: 10px;
}

.credit_firstData .invalid-feedback {
    display: flex !important;
}

.credit_firstData .custom-radio .custom-control-label::before {
    background-color: transparent;
    box-shadow: var(--inset);
}

.credit_firstData .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    background-color: rgb(52, 26, 70);
}

.credit_firstData .custom-control-input {
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.credit_firstData .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none
}

;
.credit_firstData .form-check-inline {
    display: grid !important;
}

.credit_firstData .radiorow {
    text-align: center !important;
}

.credit_firstData .custom-control.custom-radio.custom-control.item {
    padding-left: 40px;
}

.credit_firstData .custom-control-label::before {
    border: none !important;
    padding: 0;
}

.credit-header {
    box-sizing: border-box;
    height: 100%;
    box-shadow: var(--inset);
    border-radius: 8px;
    color: #aaa9aa;
    text-align: center;
    font-family: 'FuturaBT';
    background-image: linear-gradient(130deg, rgb(56 36 75) 32%, rgb(68 71 76) 100%);
}

.mat-stepper-next {
    background: transparent !important;
    border: none !important;
}

.mat-stepper-previous {
    background: transparent !important;
    border: none !important;
}

.arrow_right {
    position: relative;
    margin: 4px;
    /* padding-left: -31px; */
    padding-right: 18px;
    padding-top: 9.8px;
    padding-bottom: 9.8px;
    /* background-image: linear-gradient(
94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important; */
    color: white;
    font-size: 13px;
    /* box-shadow: var(--outset); */
    /* margin-left: 36px; */
}

.arrows-bar {
    box-shadow: var(--outset);
}

.arrow_right::after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
}

.left::after {
    left: -62px !important;
    border-color: #2b2f3400 #34383d #2b2f3400 #2b2f34;
    border-width: 29px;
    border-style: solid;
    height: 60px !important;
    margin-top: -3px;
}

.right::after {
    right: -75px;
    border-width: 29px;
    border-color: #2b2f34 #2b2f3400 #2b2f34 #46494d;
    border-style: solid;
    height: 60px;
    margin-top: -3px;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.cash_icon {
    width: 60% !important;
    /* background-color:#494c50; */
    /* background-color: #515358;; */
}

.cash_icon_w90 {
    width: 90% !important;
}

.salesreconciliation {
    text-align: center;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.cash_icon:hover,
.cash_icon_w90:hover {
    /* box-shadow: var(--inset); */
    border-radius: 5px;
    background-color: rgb(0 0 0 / 20%);
}


/* creditCard */


/* tablet_logistic */

.tablet_logistic .screen_line {
    box-shadow: var(--outset);
    background-image: linear-gradient(91deg, rgb(49 46 46) 32%, rgb(51 51 51 / 19%) 100%);
    margin: 0rem !important;
    height: 2px;
}

.tablet_logistic .screen_link {
    /* box-shadow: var(--inset); */
    /* background-image: linear-gradient(
219deg, rgb(61 63 63) 32%, rgb(51 51 51 / 19%) 100%); */
    border-radius: 6px;
    height: 35px;
}

.tablet_logistic .screen_link:hover {
    box-shadow: var(--inset) !important;
    color: var(--ternary);
}

.tablet_logistic label {
    font-size: 12px !important;
}


/* tablet_logistic */


/* emv */

.remove_tab {
    box-shadow: var(--outset);
    border-radius: 5px;
    background-image: linear-gradient(51deg, rgb(54 56 64) 32%, rgb(54 56 64) 100%);
}


/* emv */

.footer_row {
    justify-content: center;
    display: flex;
}


/* .footer_body{
  width: 100%;
} */


/* .receipt_config .footer_body .checkbox-table{
  text-align: center !important;
} */

.cdk-overlay-pane {
    display: unset;
}

.filter-textbox {
    box-shadow: var(--inset);
    background-color: var(--primary) !important;
    padding: 0;
}

.filter-textbox input {
    padding: 8px 0 8px 26px;
    background-color: var(--primary) !important;
    color: #999 !important;
}

.adjustment_detail .multiselect-dropdown .dropdown-btn,
.report-form .multiselect-dropdown .dropdown-btn,
.table_forms .multiselect-dropdown .dropdown-btn,
.currency-from .multiselect-dropdown .dropdown-btn,
.tax-select .multiselect-dropdown .dropdown-btn {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a !important;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 0.3rem 0.75rem;
    color: #999 !important;
    font-family: 'FuturaBT';
}

.adjustment_detail .dropdown-list,
.report-form .dropdown-list,
.table_forms .dropdown-list,
.currency-from .dropdown-list,
.tax-select .dropdown-list {
    background-color: var(--primary) !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    border: none !important;
    color: var(--ternary) !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    margin-top: 0px !important;
}

.adjustment_detail .dropdown-list li h5,
.report-form .dropdown-list li h5,
.table_forms .dropdown-list li h5 {
    font-size: 14px;
    margin-bottom: 0 !important;
}

.multiselect-item-checkbox:hover {
    background-color: transparent !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    width: 35px !important;
    height: 32px !important;
}

.dropdown-list .item1 {
    background: rgb(31 34 38) !important;
}

.multiselect-item-checkbox input[type=checkbox]+div {
    color: rgb(199 199 199 / 64%) !important;
}

.multiselect-item-checkbox {
    border-bottom: 1px px solid rgb(73 77 81) !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #4d2b6c !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: #4d2b6c !important;
}

.dropdown-list:hover {
    background: rgb(31 34 38) !important;
}

.top_img {
    text-align: center;
}

.hide_report {
    color: #2f3338 !important;
}

.table_footer .mat-row,
tr.mat-footer-row {
    height: 48px !important;
}

.table_footer.employee-table.dataTable .mat-row,
tr.mat-footer-row.footer {
    background-color: #26202d !important;
}

.compress_height .table-container {
    max-height: 265px !important;
}

.extend_height .table-container {
    max-height: 550px;
}


/* tags */

.tags_img {
    display: flex;
}


/* tags */


/* receipt config */

.receipt_config .label_outset {
    box-shadow: var(--outset);
    width: 104px !important;
    /* padding-left: 25px; */
    text-align: center;
    border-radius: 2px;
    background-image: linear-gradient(322deg, rgb(37 41 44) 32%, rgb(60 64 67) 100%);
}

.receipt_config .middle_checkbox {
    /* margin-left: 7rem !important; */
    justify-content: center !important;
}

.receipt_config .form-control.customTextBox::placeholder {
    text-align: center !important;
}

.receipt_config .receipt_head {
    border-bottom: 2px solid #b3b3b3;
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 21px;
}

.receipt_config .receipt_body {
    border-bottom: 2px solid #b3b3b3;
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 21px;
}

.receipt_config .receipt_bodytab {
    width: 70% !important;
    margin-left: 71px;
}

.payment_config {
    border-bottom: 2px solid #b3b3b3;
    padding-left: 15px;
    padding-right: 20px;
}

.receipt_config .footer {
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 21px;
}

.receipt_config .qr_code {
    width: 20px;
    margin-left: -15px;
}

.receipt_config .footer_receipt {
    justify-content: space-between !important;
}

.emp-name.tags {
    text-transform: capitalize;
}

.currency-input {
    position: absolute;
    /* background-color: var(--primary) !important;
  box-shadow: var(--inset); */
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    padding: 0.3rem 0.75rem 0.3rem 0.90rem;
    font-family: 'FuturaBT';
    color: #999 !important;
    border-radius: 0.25rem;
    /* background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%); */
    max-width: 119px;
    background: transparent;
    z-index: 1;
}

.currency-input-hint {
    position: absolute;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a;
    padding: 0.3rem 0.75rem 0.3rem 0.90rem;
    font-family: 'FuturaBT';
    color: #999 !important;
    border-radius: 0.25rem;
    background: transparent;
    max-width: 119px;
}

.form-group.adjustment-currency {
    margin-top: 17px;
    position: relative;
}

.form-group.adjustment-currency i.currency-icon {
    background-image: url('assets/components/images/dollar.png');
    width: 12px;
    height: 12px;
    background-size: cover;
    z-index: 99;
    position: absolute;
    left: 7px;
    top: 9px;
}

.hidden-numeric-input {
    z-index: 2;
    position: absolute;
}

@media (max-width:640px) {
    .receipt_config .receipt_head {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .receipt_config .label_outset {
        width: auto !important;
    }
    .receipt_config .receipt_bodytab {
        width: 100% !important;
        margin-left: 0px !important;
    }
    .receipt_config .middle_checkbox {
        display: grid !important;
    }
}

.label_config {
    font-size: 11px !important;
    display: flex;
    margin-left: 60px;
}

.label_config1 {
    margin-left: 35px !important;
}

.config_arrow .col-sm-12 {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.receipt_config .align .submit_button {
    background-color: transparent !important;
    border-radius: 0px !important;
    border-color: #363840;
    box-shadow: none !important;
}

.head_config {
    position: absolute;
    left: -146px;
}

.loginForm .label_config .form-control.input-lg.inputbox_login {
    width: 36px !important;
    padding: 5px !important;
}

.bottom_margin {
    position: absolute;
    top: 97%
}


/* receipt config */

.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input {
    width: 113%;
}


/* tag-resaon */

.tags_img.img_purple .img_purple,
.tags_img .img_gray {
    display: block;
    /* padding-top: 10px; */
}

.tags_img.img_purple .img_gray,
.tags_img .img_purple {
    display: none;
}

.title-reason {
    display: flex;
}


/* new css */

.job_wrap .plus_btn {
    margin-right: 11px;
}


/*resposive*/


/* tag-resaon */

@media (max-width: 768px) {
    .tablet_logistic .img_width {
        width: 100%;
    }
    .tablet_logistic label {
        font-size: 8px !important;
    }
    .tablet_logistic .img-fluid.img_logistics {
        width: 90%;
    }
    .tablet_gen .empty_row {
        height: 0px;
    }
    .tablet_gen .tablet_save {
        position: none;
        top: -7px;
    }
    .modal {
        z-index: 1050 !important;
    }
    .modal-dialog.modal-md.popUpModal {
        width: 70%;
        align-items: center;
        justify-content: center;
        text-align: center;
        left: 53px;
        top: 30px;
    }
    .multiItem_wrap .ngxMatTime {
        display: block;
    }
    table.dataTable tbody td .emp_name {
        display: inline-block;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .main-content {
        z-index: 999 !important;
        opacity: 1;
    }
    .menu-gen-form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-right: 0px;
        margin-left: -15px;
    }
    .MenuItemGeneral .customlable {
        width: max-content;
    }
}

@media (min-width: 576px) {
    .column6width {
        flex: 1 0 17.666667%;
        max-width: 18.666667%;
    }
    .img-visible {
        width: 20px;
        padding-right: 2px;
    }
}

@media (min-width: 768px) and (max-width:1366px) {
    .tablet_gen .img_width {
        width: 39px;
    }
    .multiItem_wrap .ngxMatTime {
        display: block;
    }
}

@media (min-width: 1920px) {
    .mat-expansion-panel-content {
        max-height: 100%;
    }
    .mat-expansion-panel-body,
    #accordion .card-body,
    .max-accordian .mat-expansion-panel-body {
        /* max-height: 600px; */
        max-height: 100% !important;
        overflow-y: auto;
    }
    .tableFilter,
    .table-container,
    .job_wrap {
        /* max-height: 800px !important; */
        overflow-y: auto;
        max-height: 55vh;
    }
    /* .employee_onoff.itemToggel, input[type="checkbox"]:checked ~ .toggle-button-text.itemToggel {
    width: 8%;
  }
  .employee_onoff.employeeToggle, input[type="checkbox"]:checked ~ .toggle-button-text.permissionButton.employeeToggle {
    width: 14%;
  } */
}

@media screen and (max-width:550px) {
    .img-fluid.col-sm-3 {
        width: 48%;
        /*25*/
    }
}

@media (min-width:360px) and (max-width: 640px) {
    .contactPhone {
        display: grid !important;
    }
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: transparent !important;
}

@media not all and (min-resolution:.001dpcm) {
    table.dataTable tbody th,
    table.dataTable tbody td,
    table.dataTable thead th,
    table.dataTable tfoot td {
        padding: 2px 10px !important;
    }
    .employee-table.dataTable tr {
        background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 100%, rgba(240, 240, 240, 0.19) 100%);
    }
    .item_main {
        background-image: linear-gradient(94deg, rgb(43, 47, 50) 100%, rgba(240, 240, 240, 0.19) 100%);
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #2f3338d1 inset !important;
        /* color: #fff!important; */
        background-color: var(--primary) !important;
        box-shadow: var(--inset);
        -webkit-text-fill-color: #aeaeab !important;
    }
}

@-moz-document url-prefix() {
    /* Firefox-specific rules */
    .table-container {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #34373D var(--white);
    }
    #accordion .card-body {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #34373D var(--white);
    }
}

.cdk-overlay-container {
    z-index: 9999;
}


/* .cash-management-sec .col-sm-12 .mat-form-field {
  width: auto;
} */

.cash-management-sec .col-sm-12 label {
    min-width: 145px
}

.cash-management .dropdown-btn {
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    height: 30px;
    font-size: 14px;
    border: 1px solid #39393a !important;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    padding: 5px 11px !important;
    color: #999 !important;
    font-family: 'FuturaBT';
}

.cash-management .dropdown-list {
    background-color: var(--primary) !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
    border: none !important;
    color: var(--ternary) !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    margin-top: 0px !important;
}

.cash-management .form-group .dropdown-ng {
    width: 100%;
    padding-left: 10px;
}

.cash-management .dropdown-list li h5 {
    font-size: 14px;
    margin-bottom: 0 !important;
}

.multiselect-item-checkbox:hover {
    background-color: transparent !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    width: 35px !important;
    height: 32px !important;
}

.dropdown-list .item1 {
    background: rgb(31 34 38) !important;
}

.multiselect-item-checkbox input[type=checkbox]+div {
    color: rgb(199 199 199 / 64%) !important;
}

.multiselect-item-checkbox {
    border-bottom: 1px px solid rgb(73 77 81) !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #4d2b6c !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: #4d2b6c !important;
}

.carousel-item .acclabel_wrap span.card-title {
    margin: 0;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.cash-management-sec {
    margin-top: 15px;
}

.cash-management-sec .col-sm-12 label.main-title {
    font-size: 20px;
    margin-bottom: 22px;
    font-weight: 900;
}

.global_submit label {
    background-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #aaa9aa;
    font-family: 'FuturaBT';
}

.global_submit {
    margin-top: 1em;
}

.button-none {
    display: none !Important;
}

.publish-disp-none {
    display: none;
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title {
    color: rgb(255 255 255 / 64%);
}


/*.global-submit-up{
display: flow-root !important;
}
*/

.cash-btn-pos {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), 6px 6px 14px 0 #0d0e0f, -5px -5px 20px 0 rgb(136 150 167 / 23%);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 100%, rgba(240, 240, 240, 0.19) 100%);
    padding: 9px 4px;
    border-radius: 7px;
}

.pd-Ign {
    padding: 9px 9px;
}

.global-submit .label_config {
    margin-left: 0px !important;
}

.global-form {
    display: flow-root !important;
}

.mt-0 {
    margin-top: 0px;
}

.mt-20 {
    margin-top: 20px;
}

.box_error {
    box-shadow: 0px 0px 6px #f4433600, inset 4px 4px 2px 0px #7e3842 !important;
}

.title_error {
    color: #dc3545;
}

.perm-all label {
    padding-top: 5px;
    padding-left: 0.1em;
    font-size: 16px;
}

.perm-all {
    margin-left: 1em;
    margin-top: 0.4em;
}

.checkbox-table-time label {
    font-size: 16px;
    padding-top: 6px;
}

hr.solid {
    border-top: 3px solid #bbb;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.class2 {
    border: 5px solid #224b17 !important;
}

.txt-color {
    color: #b3b4b7;
    font-size: 0.9rem;
}

.txt-color1 {
    color: #b3b4b7;
    font-size: 0.9rem;
    word-break: break-word;
}

.modalComp .notify-footer {
    justify-content: center !important;
    display: flex !important;
    width: 100%;
    margin-right: 0px;
    padding-bottom: 30px;
}


/* .modal.fade .modal-dialog {
  top: 42%;
  transform: translateY(-50%) !important;
} */

.save-cancel-popup.mt-2 {
    padding: 10px 45px 0;
    text-align: center;
}

.modalComp .notify-btns {
    display: flex !important;
    width: 43% !important;
    justify-content: center;
}

.notify {
    display: block;
}

.no-notify {
    display: none;
}

.notify-all .fade:not(.show) {
    opacity: 1;
}

.box-save {
    box-shadow: 0px 0px 6px #f4433600, inset 4px 4px 2px 0px #224b17 !important;
}

.tranCloud-giftcard {
    border-radius: 3px;
    box-shadow: var(--outset);
    margin-top: 30px;
}

.tranCloud-giftcard label {
    min-width: 130px;
}

.tranCloud-giftcard .col-sm-12.p-0 {
    max-width: 75%;
}

.price-textbox,
.projected-icon,
.job-doller-icon {
    position: relative;
}

.price-textbox i,
.projected-icon i,
.job-doller-icon i {
    position: absolute;
    top: 29px;
    left: 7px;
    font-size: 13px;
    opacity: 0.7;
}

.projected-icon i {
    top: 29px;
    left: 10px;
}

.projected-icon-30 i {
    top: 30px !important;
    left: 10px;
}

.employee-wages .price-textbox i,
.job-doller-icon i {
    top: 9px;
    font-size: 12px;
}

.annual-salaried-value .price-textbox i {
    font-size: 12px;
    top: 30px;
}

.price-textbox.percent-input input,
.projected-icon input {
    padding: 0.3rem 0.75rem 0.3rem 0.95rem !important;
}

.summery-tab .flip-table,
.summery-tab .matemployeelabel,
.summery-tab .linebar,
.summery-tab .employee_togglebutton {
    display: none;
}


/* .form-control.is-focusInvalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

.is-focusInvalid-mat .mat-select-trigger{
  border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
} */

.disp-none {
    display: none;
}

.tab-width {
    width: 16%;
}

.chck-mr {
    margin-right: 2px;
}


/* .flip-group-table tbody tr td button {
  opacity: 0;
}
.flip-group-table tbody tr td  span {
  cursor: pointer;
} */

.flip-group-table tbody tr td span.emp {
    padding: 10px;
    background-color: #2f3338 !important;
    text-transform: uppercase;
    box-shadow: 0 4px 8px 0 rgb(0 0 0), 0 6px 20px 0 rgb(0 0 0);
    border-radius: 7px;
    min-width: 150px;
    display: inline-block;
    text-align: center;
    margin: 0;
    color: #fff;
    opacity: 1;
    cursor: pointer;
}

.flip-group-table tbody tr:last-child {
    color: var(--ternary) !important;
    background-color: transparent !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    box-shadow: var(--inset);
    background-color: #26202d !important;
}

.flip-group-table tbody tr:last-child span.report-totals {
    color: var(--ternary) !important;
    font-weight: bold;
}

.flip-group-table td span.group-total,
.flip-group-table td.mat-column-discountTotal {
    font-weight: bold;
}

.flip-group-table tbody tr:last-child td button {
    opacity: 1;
    color: #fff;
    background-color: transparent;
    border: 0;
}

.img-100 {
    width: 100%;
}

.ft-14 {
    font-size: 14px;
}

.height-min {
    max-height: none;
}

.wd-print {
    width: 55%;
}

.wd-img-disc {
    box-shadow: var(--outset);
    text-align: center;
    border-radius: 2px;
    padding: 8px;
    background-image: linear-gradient(322deg, rgb(37 41 44) 32%, rgb(60 64 67) 100%);
}

table.dataTable tbody td .disc_ul {
    margin: 2px;
    padding: 5px 15px;
    text-decoration: underline;
    object-fit: contain;
    border-radius: 7px;
    /* -webkit-backdrop-filter: blur(10px); */
    /* backdrop-filter: blur(10px); */
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #ffffff; */
    /* background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%); */
    cursor: pointer !important;
    text-underline-offset: 2px;
}

.disc-ft .mat-tab-label {
    font-family: 'FuturaBT' !important;
}

.form-control.is-invalid {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
}


/* .discount-page .Communication_tab {
  background-image: none;
  border-radius: 0;
  box-shadow: none;
}

.discount-page .Communication_tab .mat-tab-group {
  background-image: linear-gradient(-136deg, rgb(64 67 72) 20%, rgb(48 52 57) 100%);
  border-radius: 3px;
  box-shadow: var(--inset);
  padding: 5px 10px;
} */

.top-sec {
    position: fixed;
    width: 93%;
    z-index: 999;
    background-color: #2f3338;
    top: 101px !important;
    margin-top: 32px;
    padding-top: 20px
}

.second-sec {
    padding-top: 70px;
    /* height: 73vh; */
    /* overflow-y: scroll; */
    overflow: hidden;
}

.second-sec.setting-config {
    height: 100vh;
}

.time-top-section {
    margin-top: 65px;
    /* display: block; */
}

.sales-area-section .global-form {
    display: none !important;
}


/* .specific-items .multiselect-dropdown .dropdown-list{
  position: static !important;
} */

.event-pricing-section .mat-accordion .mat-expansion-panel:last-child {
    overflow: visible;
}

.event-pricing-section .mat-accordion .mat-expansion-panel:last-child .mat-expansion-panel-body {
    overflow: visible;
}

.mat-calendar-header {
    backdrop-filter: blur(10px);
    box-shadow: var(--inset);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    padding-bottom: 5px;
}

.mat-calendar-table-header-divider {
    display: none;
}

.mat-calendar-table-header th {
    padding: 8px 0px 8px 0;
}

.mat-calendar-table-header th {
    padding: 8px 0px 8px 0 !important;
    font-size: 13px;
    color: var(--lightAccent);
    font-weight: 400;
    text-align: center;
    font-family: 'FuturaBT' !important;
}

.mat-calendar-body-cell-content {
    font-family: 'FuturaBT' !important;
    font-size: 13px;
    color: #bcbcbc;
}

.mat-calendar-body-label {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    visibility: hidden;
}

.mat-calendar-controls {
    margin-top: 0px !important;
    ;
    margin-bottom: 0px !important;
}

.mat-datepicker-content-container {
    box-shadow: var(--inset);
    background-color: var(--primary);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
}

.mat-datepicker-content .mat-calendar {
    width: 270px !important;
    height: auto !important;
}

.mat-calendar-controls {
    color: #fff;
}

.mat-calendar-arrow {
    border-top-color: #fff;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    color: #fff;
}

.mat-button-wrapper {
    font-family: 'FuturaBT' !important;
}

.mat-calendar-body-selected {
    background-color: var(--accent) !important;
}

.mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #a048c347;
}

.datepicker {
    width: -webkit-fill-available !important;
    height: fit-content !important;
    width: -moz-available !important;
}

.sales-daypart table.dataTable thead th,
.sales-daypart table.dataTable tbody td,
.sales-daypart table.dataTable thead th,
.sales-daypart table.dataTable tfoot td,
.summary-section table.dataTable thead th,
.summary-section table.dataTable tbody td,
.summary-section table.dataTable thead th,
.summary-section table.dataTable tfoot td {
    min-width: 104px;
}

.sales-daypart table.dataTable .mat-sort-header-content,
.summary-section table.dataTable .mat-sort-header-content {
    text-align: left;
}

.store-pre-text {
    font-size: 12px;
    padding-left: 10px;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgb(255 255 255 / 38%) !important;
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: #bcbcbc;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* .summary-report-form .report-datepicker {
  height: 30.5px !important;
} */

.offlie-alert-msg span {
    color: red;
    display: flex;
    align-items: baseline;
    padding-left: 30px;
}

.offlie-alert-msg span i {
    padding-right: 7px;
}

.employee-popup .loginForm {
    pointer-events: none;
    opacity: 0.7;
}

.Labels-txt {
    background-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #aaa9aa;
    font-family: 'FuturaBT';
}

.disp-blck {
    display: block;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.pd-bottom5 {
    padding-bottom: 5px !important;
}

.prints-addmb {
    display: block !important;
    margin-bottom: 15px;
}

.custom-check .custom-control-label::after,
.custom-check .custom-control-label::before {
    height: 22px !important;
}

.custom-tipInp {
    height: 25px !important;
    width: 22px !important;
}

.top-grat {
    top: -7px;
}

.pl-15 {
    padding-left: 15px !important;
}

.gift-icon {
    position: absolute;
    top: -108%;
    width: 7rem;
    right: 4%;
}

.edit-icon-align {
    position: absolute;
    top: -93%;
    right: 85%;
    /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff; */
    color: #a1a3a6;
    font-size: 1.2rem;
    cursor: pointer;
}

@media(min-width: 1800px) and (max-width: 2100px) {
    .gift-icon {
        position: absolute;
        top: -108%;
        width: 6rem;
        right: 4%;
    }
    .edit-icon-align {
        position: absolute;
        top: -96%;
        right: 34%;
        /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff; */
        color: #a1a3a6;
        font-size: 1rem;
    }
}

.no-pointers {
    pointer-events: none;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.pt-10 {
    padding-top: 10px;
}

.mt-20 {
    margin-top: 20px !important;
}

.card-outline {
    width: 348px !important;
    float: right;
}

.card-ht {
    height: 228px;
    width: 348px;
    border-radius: 0px !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.back-card {
    background: #b1b1b3;
}

.file-upload-wrapper {
    color: #4daf7c;
    height: 30px;
    position: relative;
    width: 100%;
}

.file-upload-wrapper::after {
    content: attr(data-text);
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    padding: 10px 15px;
    display: block;
    pointer-events: none;
    z-index: 999;
    width: 26.5%;
    height: 30px;
    line-height: height - 20px;
    color: #999;
    border-radius: 5px 10px 10px 5px;
    font-weight: 300;
    background-color: var(--primary) !important;
    box-shadow: var(--inset);
    border: 1px solid #39393a;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
}

.file-upload-wrapper span.upload-text {
    position: absolute;
    top: 2px;
    right: 3px;
    display: inline-block;
    height: 25px;
    color: #fff;
    z-index: 9999;
    font-size: 14px;
    text-align: center;
    line-height: 15px;
    padding: 0.3rem 0.75rem;
    pointer-events: none;
    border-radius: 0 5px 5px 0;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #958e8e !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgb(3 3 3 / 19%) 100%) !important;
    font-weight: bold;
    font-family: 'FuturaBT';
    color: #999 !important;
    border-radius: 0.25rem;
    width: 25.5%;
}

.input-upload {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: height - 20px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
}

.pd-card {
    padding: 12px 12px !important;
}

.gift-front img {
    max-width: 300px;
    max-height: 130px;
    padding: 0 0px;
}

.ptb-3 {
    padding: 3px 0px !important;
}

.custom-text {
    padding-top: 0px !important;
    overflow: none;
    font-size: 13px;
    padding-bottom: 0px !important;
    line-height: 13px;
}

.upper-div {
    height: 20.928px;
}

.magic-stripe {
    height: 38.4px;
    max-height: 38.4px;
    background: #2b2e34;
    margin-bottom: 7px;
}

.card-number {
    max-height: 38.4px;
    padding-top: 4px;
    padding-bottom: 3px;
    overflow: hidden;
    max-width: 250px;
    /*220*/
    margin-left: auto;
    margin-right: auto;
}

.message-div {
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    max-width: 324px;
    height: 40px;
    max-height: 40px;
    width: 100%;
}

.message-div h6 {
    font-size: 12px;
    line-height: 10px;
}

.is-whiteCard-bg {
    background: #b1b1b3 !important;
}

.is-whiteCard-bg p,
.is-whiteCard-bg h6 {
    -webkit-text-fill-color: #000;
    color: #000;
    -webkit-text-fill-color: #2f3338;
    color: #2f3338;
    text-shadow: none;
    font-weight: 600;
}

.is-blackCard-bg {
    box-shadow: var(--inset);
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    -webkit-text-fill-color: #d6d5d5cc;
    color: #d6d5d5cc;
}

.ft-16 {
    font-size: 16px;
}

.back-ft-color {
    color: #2b2e34 !important;
    -webkit-text-fill-color: #2b2e34;
    text-shadow: none;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
}

.event-pricing .mat-expansion-panel-content {
    max-height: none;
}

.event-pricing .mat-expansion-panel-body {
    max-height: none;
    overflow-y: auto;
    height: auto;
}

.items-dropdown .dropdown-list {
    overflow: visible;
}

.general-overflow {
    overflow: visible !important;
}

.general-overflow .mat-expansion-panel-body {
    overflow: visible;
}

.conditional-z-index-genPricing {
    z-index: 999;
}

.conditional-z-index-EventPricing {
    z-index: 0;
}

::file-selector-button {
    display: none;
}

::-webkit-file-upload-button {
    display: none;
}

.hideColorGiftCard {
    background: none !important;
}

.giftCardBtn:active,
.giftCardBtn:focus {
    background-color: rgb(52, 26, 70) !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.giftCardBtnSelected {
    background-color: rgb(52, 26, 70) !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
}

.pop-up-bg {
    display: inline-block;
}

.first-class {
    transform: translateY(135%) !important;
    background-color: #3e4246 !important;
    z-index: 2000 !important;
}

.first-class1 {
    transform: translateY(5%) !important;
    background-color: #3e4246 !important;
    z-index: 2000 !important;
}

.navigate-btn {
    box-shadow: 0 2px 4px 0 #7e3842, 0px 1px 4px 5px #7e3842 !important;
}

.pop-up-bg .button-style {
    color: #fff;
    background-color: #2f3338;
    min-width: 131px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    padding: 6px 10px;
    border: none;
    color: var(--ternary) !important;
    margin-right: 15px;
    margin-left: 15px;
}

.pop-up-bg .close {
    color: #b3b4b7;
}

.modal-popUp .modal-content {
    background-color: #3e424600 !important;
    z-index: 2000 !important;
    border: none;
}

.min-height-head {
    min-height: 49.38px;
}

.time-punch-table .submit_button {
    position: relative;
    top: -8px;
    right: 10px;
}

.fliptable-search input {
    height: 21px;
    padding: 5px 10px;
    margin-left: 5px;
    color: #b4b5b7;
}


/* .recipe-dragdrop {
  flex: 0 0 66.66%;
  max-width: 66.66%;
} */

.dropdrop-filter-table {
    width: 100%;
}

.dropdrop-filter-table .title {
    width: 20%;
    display: flex;
}

.dropdrop-filter-table .dragdrop-search {
    width: 80%;
    display: flex;
}

.dropdrop-filter-table .dragdrop-search input {
    width: 100%;
    margin: 0 !important;
}

.dropdrop-filter-table .mat-sort-header-content {
    width: 100%;
}

.sales-area-layout {
    position: fixed;
    right: 12px;
    z-index: 999;
    background-color: #2f3338;
    top: 101px !important;
    margin-top: 32px;
    padding: 20px 4px 20px 0px;
    width: 93%;
}

.sales-area-accordion {
    padding-top: 69px;
}

.sales-area-accordion .item-layout-dragdrop .left-table {
    flex: 0 0 33.333333%;
    max-width: 25%;
}

.sales-area-accordion .item-layout-dragdrop .recipe-dragdrop {
    flex: 0 0 75%;
    max-width: 75%;
}

.menu-modifier-search .menu-item-searchfield .mat-sort-header-container {
    display: block;
}

.menu-item-searchfield .dragdrop-search {
    display: none;
}

.menu-modifier-search .menu-item-searchfield .mat-sort-header-content {
    margin-top: 5px;
}

.menu-modifier-search.menu-modifier-search .menu-item-searchfield .title {
    width: 30%;
}

.menu-modifier-search .menu-item-searchfield .dragdrop-search {
    width: 70%;
    display: flex;
}

.menu-modifier-search .menu-item-searchfield .dragdrop-search .form-control {
    margin-top: 0 !important;
}

.hide-btn {
    display: none !important;
}

.state-placeholder.mat-form-field .mat-form-field-label {
    display: none !important;
}

.state-placeholder.mat-form-field-hide-placeholder .mat-select-placeholder {
    -webkit-text-fill-color: #6f7172 !important;
    text-shadow: none !important;
    font-size: 12px !important;
    font-weight: 600;
}

.report-audit-font {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.tdwidth-100 {
    width: 59% !important;
}

.tsys-header {
    box-sizing: border-box;
    height: 100%;
    box-shadow: var(--inset);
    border-radius: 8px;
    color: #aaa9aa;
    text-align: center;
    font-family: 'FuturaBT';
    background-image: linear-gradient(130deg, rgb(56 36 75) 32%, rgb(68 71 76) 100%);
}

.time-edit-table .table-container {
    max-height: 45vh;
}

.sales-area-section .right-sec {
    height: 62vh;
    overflow-y: scroll;
}

.sales-area-section .left-sec {
    height: 62vh;
    overflow-y: scroll;
}

.sales-area-section .col-sm-4::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

.menu-layout-bulider {
    height: 72vh;
    overflow-y: auto;
}

.report-table {
    max-height: 62vh;
    overflow-y: auto;
}

.arrow-disp .mat-sort-header-arrow {
    transform: none !important;
    opacity: 1 !important;
}

.tags-icon {
    filter: drop-shadow(7px 5px 2px #000000d1);
    -webkit-filter: drop-shadow(7px 5px 2px #000000d1);
    cursor: pointer;
}

#tooltip {
    opacity: 0;
    position: absolute;
    background-color: black;
    color: white;
    top: 0;
    left: 0;
}

#tags-icon:hover #tooltip {
    opacity: 1;
}

.input-btn1 input {
    max-width: 73.5%;
    border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.mat-select-panel {
    max-width: 150px;
}

.error-msg>.err {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
}

.setting-error-msg label {
    min-width: 34%;
}

.setting-page .mat-accordion .mat-expansion-panel {
    overflow: visible;
}

.setting-page .mat-expansion-panel-body {
    overflow: inherit
}

.currency-from input,
.setting-error-msg input {
    text-align: right;
}

.houseLogistic .hide {
    display: none;
}

.houseLogistic .show {
    display: flex;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #624f93;
}

input[type=number]::-webkit-inner-spin-button {
    cursor: pointer !important;
    -webkit-appearance: inner-spin-button;
    background-color: none !important;
}

.input-box {
    position: relative;
}

.input-box:before {
    position: absolute;
    top: 0;
    right: 6px;
    color: #eceff1;
    font-size: 9px;
    content: "▲";
    pointer-events: none;
}

.input-box:after {
    position: absolute;
    bottom: 0;
    right: 6px;
    color: #eceff1;
    font-size: 9px;
    content: "▼";
    pointer-events: none;
}

.input-box .input {
    -webkit-appearance: none;
}

.input-box .input:focus {
    outline: none;
}

.input-box .input::-webkit-inner-spin-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: scale(5);
    transform-origin: right center;
    opacity: 0;
    cursor: pointer;
}

.input-box .input::-webkit-contacts-auto-fill-button {
    opacity: 0;
}

.tagAlign .layout_container {
    height: auto !important;
    max-height: 300px;
}

.allow-pointer {
    pointer-events: auto;
}

.ccgateway-preloader {
    position: absolute;
    top: 40% !important;
    right: 51% !important;
}

.tabs-disp {
    display: flex;
    box-shadow: var(--inset);
    background-color: var(--primary);
    width: auto;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    vertical-align: middle;
    border-radius: 7px;
    padding-top: 5px;
    font-size: 16px;
}

.cc-card {
    box-shadow: var(--inset);
    height: auto !important;
    overflow-y: auto !important;
    margin-top: 14px;
}

.cc-card .mat-tab-label {
    height: auto !important;
    display: flex;
    width: auto;
    vertical-align: middle;
    border-radius: 7px;
    padding-top: 5px;
    font-size: 16px;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: var(--inset);
    background-color: var(--primary);
    width: auto;
    background: linear-gradient(-136deg, rgba(52, 55, 61, 1) 20%, rgba(27, 31, 36, 1) 100%);
    margin-right: 23px;
}

.cc-card .mat-tab-label-active,
.cc-card .mat-tab-label-active {
    background: linear-gradient(-136deg, rgb(69 67 67) 20%, rgb(12 14 17) 100%) !important;
    box-shadow: 0px 4px 4px 3px rgb(0 0 0 / 50%), 6px 6px 14px 0 #000000, -5px -5px 20px 0 rgb(136 150 167 / 23%) !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #181818;
    opacity: 0 !important;
}

.cc-card .mat-tab-labels {
    padding: 0 0 15px;
    opacity: 0.9 !important;
}

.cc-card .mat-tab-label {
    opacity: 0.9 !important;
}

.cc-card .mat-tab-label .mat-tab-label-content {
    font-family: 'FuturaBT' !important;
}

.cc-card .mat-tab-body-content {
    font-family: 'FuturaBT' !important;
}

.custom-ft {
    margin-bottom: 15px;
    font-weight: 600;
}

.cc-disp-none {
    display: none !important;
}

.success-green {
    -webkit-text-fill-color: #4bcc32cc;
}

.fail-red {
    -webkit-text-fill-color: #ef6666cc;
}

.mt-15 {
    margin-top: 15px
}

.gift-card-front,
.gift-card-back {
    max-width: 348px;
    margin-left: auto;
    margin: top 20px;
}

.gift-card-front .card-class,
.gift-card-back .card-class {
    text-align: center;
    padding-top: 10px;
    display: inline-block;
    width: 100%;
    background-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #aaa9aa;
    text-transform: capitalize;
    font-family: 'FuturaBT';
}

.giftform .gift-card-icon {
    position: relative;
}

.adjustment-sec .dropdown-list {
    max-height: 197px;
    overflow: hidden;
}

.adjustment-sec .dropdown-list .item2 {
    max-height: 150px !important;
}

.event-pricing-sec .dropdown-list {
    max-height: 235px;
    overflow: hidden;
}

.event-pricing-sec .dropdown-list .item2 {
    max-height: 150px !important;
}

.dashboard-img .mat-expansion-panel:first-child .mat-expansion-panel-body {
    padding: 16px 0 !important;
}

.mt-25 {
    margin-top: 25px;
}

.scroll-pos {
    max-height: 172px;
    overflow-y: auto;
    overflow-x: hidden;
}

.separate-blocks {
    border-right: 1px solid #adacad94;
}

textarea.input-cst-text {
    resize: none;
    height: 140px !important;
}

.time-edit-flip {
    max-height: 56vh !important;
}

.pl-15 {
    padding-left: 15px;
}

.min-time-class {
    display: inline-flex;
    width: auto;
}

.disp-mins {
    position: relative;
    width: 22% !important;
    display: inline-flex;
}

.cust-txt {
    height: 26px !important;
}

.span-disp {
    background-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #aaa9aa;
    font-family: 'FuturaBT';
}

.img-label-logistics {
    color: #d0e8d0;
    padding: 3px;
    border-radius: 0.3rem;
    cursor: pointer;
    left: 2px;
    width: fit-content;
    z-index: 9999;
    position: sticky;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff;
    background-image: linear-gradient(94deg, rgb(44 42 42 / 71%) 32%, rgb(140 135 135 / 19%) 100%);
    width: 70px;
    display: flex;
    justify-content: center;
    background-color: var(--primary) !important;
}

.pl-0 {
    padding-left: 0px;
}

.store-logo-sec .store-logo {
    position: absolute;
    right: 10px;
    /*5*/
    top: -125px;
    /*62*/
}

.store-logo-sec .mat-elevation-z8.outshadow {
    width: 240px;
    padding: 10px 15px 0;
    max-height: none;
}

.store-logo-sec .store-logo-input label {
    margin: 3px auto;
}

.store-logo-sec .logisticcanvas {
    margin: 10px auto;
    display: inline-block;
    /* width: 200px;
  height: 200px; */
}

.item_assigned .modifier-arrow-icon {
    display: flex;
}

.item_assigned .modifier-arrow-icon .mat-sort-header-arrow {
    position: absolute;
    top: 0px;
    right: 11px;
    color: rgb(172, 173, 175);
    transform: translateY(25%);
    opacity: 1 !important;
}

.tax-slide {
    display: flex;
    align-items: center;
}

.tax-slide .mat-slide-toggle {
    margin: 0 8px;
}

.tax-slide .mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #000000ba;
    height: 19px;
    width: 41px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
}

.tax-slide .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-image: linear-gradient(303deg, rgb(78 52 120) 32%, rgb(21 21 31) 100%) !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff !important;
    background-color: transparent;
}

.tax-slide .mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: rgb(60 63 66) !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(176, 109, 255, 0), inset 1px 1px 2px 0 var(--white);
    background-image: linear-gradient(164deg, rgba(255, 255, 255, 0) 38%, rgba(240, 240, 240, 0.19) 89%);
    height: 19px;
    width: 19px;
}

.tax-slide .mat-slide-toggle-thumb-container {
    top: 0;
}

.tax-slide .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    top: 0;
    left: 6px;
}

.tax-slide span {
    font-family: 'FuturaBT';
}

.menu-sel {
    width: 9rem !important;
    z-index: 999;
    position: relative;
    margin-top: 7px;
}

.cc3-submit {
    width: auto;
    bottom: 12px;
    position: absolute;
    right: 2%;
    padding-left: 24px;
    padding-right: 24px;
}

.pd-2 {
    padding: 0px 2px;
}

.MenuItemGeneral .custom-color {
    color: var(--custom-subheader-color)
}

.ccgateway-custom-color {
    -webkit-text-fill-color: var(--custom-subheader-color)!important;
}

.custom-color {
    color: var(--custom-subheader-color)!important;
    margin-bottom: 10px !important;
}

.custom-color-npx {
    color: var(--custom-subheader-color)!important;
}

.customlable-bold {
    font-weight: bold;
}

.customlable-sales {
    background-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #aaa9aa;
    font-family: 'FuturaBT';
}

.menu-readonly {
    pointer-events: none;
}

.dropdwn-height .dropdown-btn {
    height: 35px !important;
}

.dropdwn-height {
    display: block;
    margin-bottom: 5px;
}

.multiselect-dropdown-menu .dropdown-btn {
    padding: 5px 10px !important;
}

.disp-fl {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

.label-width {
    width: max-content;
}

.credit-width {
    width: 55%;
}

.Taxable-width {
    width: 42%;
}

.img-size {
    width: 180px;
    height: 180px;
}

.img-position {
    position: initial;
    padding-left: 1em;
}

.store-lbl-pad {
    padding-bottom: 15px;
}

.radio-box-width {
    width: 14em;
}

.store-lbl-gap {
    padding-top: 3px;
    padding-bottom: 5px;
}

.menu-lbl-gap {
    padding-top: 10px;
    padding-bottom: 5px;
}

.size-price {
    margin-top: -18px
}

.dropdown-up .dropdown-list {
    top: auto !important;
    bottom: 100% !important;
}

.dropdown-hight .item2 {
    max-height: 120px !important;
}


/* .mat-select-panel .mat-primary{
position: relative !important;
} */

.ipad-width {
    width: 90%;
}

.del-icon-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img-del-label .img-label-logistics {
    display: flex;
    align-items: center;
    left: 2px !important;
    padding: 4px 5px;
}

.delete-img {
    width: 20px;
}

.dlt-btn {
    border: none;
    margin: 4px 0px 2px 20px;
    width: 28px;
}

.menu-del-icon-div {
    display: flex;
    justify-content: initial;
    align-items: center;
}

.menu-img-dlt {
    margin-left: 15px;
}

.dlt-i {
    font-size: 22px;
    color: #aaa9aa;
    font-weight: 500;
}

.gift-dlt-btn {
    border: none;
    width: 32px;
    height: 30px;
    float: right;
}


/* .search-bg-error{
  background: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%) !important;
  font-size: 80%;
  color: #dc3545;
  -webkit-text-fill-color: #dc3545;
} */

.multiselect-dropdown .no-data h5 {
    font-size: 0.7rem !important;
}

.switch-disable {
    pointer-events: none;
}

.leftmost-panel {
    left: 16%;
}

.leftmost-panel .mat-pseudo-checkbox {
    color: #4d2b6c;
}

.leftmost-panel-color .mat-pseudo-checkbox {
    color: #4d2b6c;
}

.disp-check {
    display: flex;
    align-self: flex-end;
}

@-moz-document url-prefix() {
    .disp-check {
        display: flex;
        align-self: flex-end;
        padding-bottom: 5px;
    }
}

.flip-group-table tbody tr:last-child .disc_ul-total {
    background-color: #2f333800 !important;
    box-shadow: none;
    text-decoration: none;
}

.flip-cascade .cascade-footer {
    color: var(--ternary) !important;
    background-color: transparent !important;
    background-image: linear-gradient(94deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 100%);
    box-shadow: var(--inset);
    background-color: #26202d !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.labor-header-no {
    pointer-events: none;
}

.labor-header {
    text-decoration: underline;
    cursor: pointer;
}

.labor-header-span {
    display: none;
}

.adj-chck {
    margin-left: 70px !important;
}

.txt-transform {
    text-transform: uppercase;
}

.gft-rr-gen {
    display: flex;
    justify-content: space-evenly;
}

.str-emp-wj {
    justify-content: space-between;
}


/* responsive design */


/* tab view */

@media screen and (max-width:1024px) {
    .custom_Uber_eats .row {
        margin-bottom: 10px;
    }
    .receipt-pad {
        padding-right: 65px !important;
    }
    .store-logo-sec .mat-elevation-z8.outshadow {
        width: 185px;
    }
    .img-size {
        width: 130px;
        height: 130px;
    }
    .ngxMatTime .ngx-mat-timepicker {
        display: inline-flex;
    }
    .gft-gen-resp .custom-control {
        display: flex;
        flex-direction: column;
    }
    .gft-gen-resp .custom-control-label {
        padding-left: 10px;
    }
}

@media screen and (min-width:767px) and (max-width:992px) {
    .gift-card-width {
        width: max-content;
    }
    header {
        min-width: 30%;
    }
    /* .top-header{
    min-width: 348px !important;
  }
  .card-outline{
    float:none;
  } */
    .theme_titlecard {
        width: 40%;
    }
    .person-wrap {
        width: 30%;
    }
    table.dataTable tbody td .emp_name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 121px;
        /*320*/
    }
    .fliptable-search .mat-form-field-infix {
        max-width: 180px;
    }
    .fliptable-search .matemployeelabel {
        width: auto;
    }
    .second-sec>.col-sm-8 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
    }
    .second-sec>.col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
    }
    .top-header img {
        max-width: 52px;
        min-width: auto;
        padding: 0 5px;
    }
    .page-title h3 {
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 19px;
    }
    .top-sec .col-sm-4 {
        flex: 0 0 70%;
        max-width: 70%;
    }
    .top-sec .col-sm-8 {
        flex: 0 0 30%;
        max-width: 30%;
    }
    .top-sec {
        margin-top: 0;
    }
    .second-sec {
        padding-top: 50px;
    }
    .submit_parameter label {
        font-size: 13px;
    }
    .card.credit_card .mat-horizontal-content-container {
        padding: 0 0 24px 0;
    }
    .card.credit_card label {
        font-size: 12px;
    }
    .tsys_button,
    .step_button {
        padding: 10px 29px 0;
    }
}

@media screen and (min-width:760px) and (max-width:1441px) {
    .permision-label-pad {
        padding-left: 10px;
    }
    .logisticcanvas {
        width: max-content;
    }
    /* .max-accordian .mat-expansion-panel-content{
     overflow-x: scroll;
   } */
    .radio-width {
        width: max-content;
    }
    .header-pad {
        top: -15px;
    }
    .mat-expansion-panel-content .mat-expansion-panel-body {
        /* width: max-content; */
        overflow: scroll;
        overflow-x: hidden;
    }
    .receipt-resonsive {
        width: max-content;
        overflow: scroll;
        overflow-x: hidden;
    }
    .receipt-pad {
        padding-right: 30px;
    }
    .receipt_config .footer_receipt {
        justify-content: space-around !important
    }
    ;
    .ip-responsive {
        /* in tablet-general-component ip main div ln:109*/
        display: contents;
    }
    .label-pad {
        padding-left: 15px;
    }
    .password-strength .point {
        width: 13% !important;
    }
    .multi-select-dd span span {
        max-width: 50% !important;
        padding-right: 13px !important;
    }
}


/* mobile view */

@media screen and (max-width: 767px) {
    .gift-card-front,
    .gift-card-back {
        max-width: 348px;
        margin-left: 40px;
        margin-top: 50px;
    }
    .gift-dlt-btn {
        margin: 7px 2px 5px 3px;
    }
    .switch-width {
        width: 3.3em;
    }
    .perm-all label {
        padding-left: 1.1em;
    }
    .loginheader {
        padding: 0;
    }
    .switch input[type="checkbox"]:checked~.toggle-button-text {
        width: 95%;
    }
    .store-switch {
        width: 32% !important;
    }
    .emp-switch {
        width: 11% !important;
    }
    .size-price {
        margin-top: -5px
    }
    .user_card {
        max-width: 340px;
        margin: 0 auto;
    }
    header {
        width: 50% !important;
        min-width: 50%;
    }
    a.logo {
        width: 100%;
    }
    #user-icon-wrap {
        float: none;
        margin-top: 0;
        margin-right: 6px;
    }
    .user-detail-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
    }
    .carouse_wrapper .carousel-indicators {
        display: flex !important;
    }
    .custom-control-label {
        padding-left: 20px;
    }
    .carousel .inside_wrap {
        margin: 0;
    }
    .dropright .dropdown-menu {
        top: 75px !important;
        right: 0;
        width: 100%;
        min-width: 160px;
        left: 100% !important;
        margin-top: -72px !important;
    }
    .mat-select-panel.mat-primary {
        top: 25px;
    }
    .mat-select-panel .mat-optgroup-label,
    .mat-select-panel .mat-option {
        height: 3rem !important;
    }
    .footer-table .back-img {
        display: block;
    }
    .footer-table .back-img p {
        margin: 0;
    }
    [mat-align-tabs=center]>.mat-tab-header .mat-tab-labels {
        justify-content: left !important;
    }
    .mat-tab-label {
        padding: 0 16px !important;
    }
    .footer-table .mat-tab-label .mat-tab-label-content {
        font-size: 16px !important;
    }
    header {
        width: 50% !important;
        min-width: 50%;
        padding: 0;
    }
    .user-detail-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
    }
    .carouse_wrapper .carousel-indicators {
        display: flex !important;
    }
    .custom-control-label {
        padding-left: 20px;
    }
    .carousel .inside_wrap {
        margin: 0;
    }
    .dropright .dropdown-menu {
        top: 75px !important;
        right: 0;
        width: 100%;
        min-width: 160px;
        left: 100% !important;
        margin-top: -72px !important;
    }
    .main-content {
        z-index: 1000 !important;
        opacity: 1;
        padding: 10px 15px;
        background-image: none !important;
    }
    .mat-select-panel.mat-primary {
        top: 25px;
    }
    .mat-select-panel .mat-optgroup-label,
    .mat-select-panel .mat-option {
        height: 3rem !important;
    }
    .footer-table .back-img {
        display: block;
    }
    .footer-table .back-img p {
        margin: 0;
    }
    [mat-align-tabs=center]>.mat-tab-header .mat-tab-labels {
        justify-content: left !important;
    }
    .mat-tab-label {
        padding: 0 16px !important;
    }
    .footer-table .mat-tab-label .mat-tab-label-content {
        font-size: 16px !important;
    }
    .text-box {
        font-size: 20px;
    }
    .top-sec {
        top: 75px !important;
        width: 100%;
        align-items: flex-end;
    }
    .top-sec .col-sm-4 {
        flex: 0 0 70%;
        max-width: 70%;
    }
    .top-sec .col-sm-8 {
        flex: 0 0 30%;
        max-width: 30%;
    }
    .top-sec .plus_btn {
        padding: 0 6px;
        line-height: 1.5em;
    }
    .top-sec .plus_btn i {
        vertical-align: 1px;
        font-size: 12px;
    }
    .global-form .submit_button {
        border-radius: 4px;
    }
    .global-form .submit_button img {
        height: auto;
        max-width: 30px;
    }
    .second-sec {
        padding-top: 100px;
        overflow: auto;
    }
    .toolbar {
        background-color: #2f3338;
        height: 50px;
        position: fixed;
        top: 62px;
        width: 100%;
        /* z-index: 999; */
    }
    .second-sec>.col-sm-8 {
        margin-top: 20px;
        overflow-y: auto;
        height: auto;
    }
    /* header .toolbar,
  .person-wrap .toolbar {
    display: none;
  } */
    .second-sec>.col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .second-sec>.col-sm-8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .person-wrap {
        width: 50%;
        margin: 10px 0 !important;
    }
    .fliptable-search .matemployeelabel {
        width: auto;
    }
    .employee_togglebutton {
        justify-content: flex-end;
    }
    .Employee_Toggle {
        font-size: 14px;
    }
    .employee_togglebutton .switch {
        width: 40px;
        height: 55px;
    }
    .slider.round:before {
        top: 2px;
        width: 15px;
        height: 15px;
    }
    .fliptable-search .mat-form-field-infix {
        width: auto;
    }
    .toggle-button-text {
        height: 16px;
    }
    .fliptable-search input {
        color: rgba(255, 255, 255, 0.5);
    }
    .gift-front .card-ht,
    .back-card .card-ht {
        width: 100%;
    }
    .gift-front.card-outline,
    .back-card.card-outline {
        width: 100% !important;
    }
}

@media screen and (max-width: 574px) {
    header .toolbar {
        display: block;
    }
    .credit-width {
        width: 100%;
    }
    .img-size {
        width: 180px;
        height: 150px;
    }
    .gft-crd-gen {
        display: flex;
        justify-content: end;
    }
    .edit-icon-align {
        position: relative;
        right: 20%;
    }
    .gift-icon {
        position: sticky;
    }
}

@media screen and (min-width:574px) and (max-width:766px) {
    .top-sec {
        width: 90%;
    }
    .store-logo-sec .mat-elevation-z8.outshadow {
        width: 220px;
        padding: 10px 10px 0;
    }
    .edit-icon-align {
        position: relative;
        right: 23%;
    }
}

@media screen and (min-width: 560px) and (max-width: 766px) {
    .img-size {
        width: 180px;
        height: 150px !important;
    }
    .edit-icon-align {
        position: relative;
        right: 20%;
    }
    .gft-crd-gen {
        display: flex;
        justify-content: end;
    }
    /*****/
    .gift-icon {
        position: relative !important;
    }
    .tab-view {
        padding-top: 30px;
    }
}

@media screen and (max-width: 567px) {
    .content {
        margin-left: 0;
    }
    .gft-crd-gen {
        display: flex;
        justify-content: end;
    }
    .menuitemlogisticrow {
        padding: 7px 35px 6px 35px;
    }
}

@media screen and (min-width: 559px) and (max-width: 766px) {
    .img-size {
        width: 180px;
        height: 150px !important;
    }
    .edit-icon-align {
        position: relative;
        right: 21%;
    }
    .gft-crd-gen {
        display: flex;
        justify-content: end;
    }
    .gift-icon {
        position: relative !important;
    }
    .tab-view {
        padding-top: 30px;
    }
    .logistics .mat-slider-horizontal {
        min-width: 88%;
    }
    .menuitemlogisticrow {
        padding: 7px 39px 6px 45px;
    }
}

@media (min-width:641px) and (max-width:767px) {
    header {
        width: 40% !important;
        min-width: 20%;
    }
    .slider.round .emp :before {
        top: 1px !important;
    }
    .theme_titlecard {
        z-index: 9999;
        width: 40%;
    }
    .person-wrap {
        width: 40%;
    }
    .top-header .card {
        padding: 7px 10px;
    }
    .top-header .title_img {
        width: 30%;
    }
    .top-header .page-title {
        width: 70%;
    }
    .top-header .title_img img {
        max-width: 40px;
        min-width: auto;
        max-height: inherit;
        padding: 0;
    }
    .theme_titlecard .title-reason p {
        font-size: 14px;
    }
    .theme_titlecard .card-title {
        font-size: 16px;
    }
    .top-sec {
        top: 35px !important;
        z-index: 9999;
    }
    .second-sec {
        padding-top: 70px;
    }
    .toolbar {
        left: 0;
    }
}

@media (min-width:765px) and (max-width:768px) {
    .navbar-expand .navbar-nav {
        flex-direction: column;
    }
}

@media(min-width: 2111px) {
    .gift-icon {
        position: absolute;
        top: -108%;
        width: 9vh;
        right: 4%;
    }
    .edit-icon-align {
        position: absolute;
        top: -96%;
        right: 18%;
        /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 50%), inset 1px 1px 2px 0 #ffffff; */
        color: #a1a3a6;
        font-size: 1rem;
        cursor: pointer;
    }
}


/* safari only  */

.safari-tbl .employee-table.dataTable tr {
    display: inline-table !important;
    width: 100% !important;
}

.safari-tbl table.dataTable tbody td,
.safari-tbl .table-width-safari thead th,
.table-width-safari thead th {
    width: 65% !important;
}

.safari-tbl table.dataTable thead th,
.safari-tbl table.dataTable tfoot td {
    width: 20%;
}

.safari-tbl table.dataTable.table_center .mat-cell {
    width: 20% !important;
}

.safari-tbl .mat-scroll-safari .mat-expansion-panel-body {
    overflow: visible !important;
}

.safari-tbl .dataTable thead {
    position: -webkit-sticky !important;
    top: 0 !important;
    z-index: 999 !important;
}

.safari-tbl .items-dropdown .dropdown-list .item2 {
    max-height: 130px !important;
}


/* .safari-tbl .safari-gift-image{
  ;
} */